import { marketIsDynamic } from "utils/eventUtils";
import { betOption } from "constants/bets";

const checkRange = (v, min, max, name) => {
  if (!(v >= min && v <= max)) throw new Error("Invalid " + name);
};

const encodeBet = ({ event, market, value = 0, side }) => {
  checkRange(event, 1, 0xffffffff, "event"); // 32..63| 32 bit
  // stupid JS doesn't have normal Long type =\
  const secondInt = (event & 0xffffffff) >>> 0; // last 32 bits, don't convert freaking event to int32 for right shift...

  checkRange(market, 1, 255, "market"); // 24..31| 8 bit

  let firstInt = 0;
  if (marketIsDynamic(market)) {
    checkRange(side, 0, 0, "side"); // not encoded
    value = Math.round(value) >>> 0; //
    checkRange(value, 0, 0xffffff, "value"); // 24 bit, value 0...

    firstInt = (value | (market << 24)) >>> 0;
  } else {
    checkRange(side, 0, 255, "side"); // 0...7| 8 bit
    checkRange(value, -32768 / 4.0, 32767 / 4.0, "value"); //8..23| 16 bit, value -8191 ... 8192
    value = Math.round(value * 4 + 32768) >>> 0;
    checkRange(value, 0, 0xffff, "value"); // 16 bit

    firstInt = (side | (value << 8) | (market << 24)) >>> 0;
  }
  // console.log(firstInt);

  return secondInt.toString(16) + firstInt.toString(16).padStart(8, "0");
};

const encodeOdd = (odd) => {
  odd = Math.round(odd * 100);
  return odd.toString(10);
};

export const buildBetMemo = (
  bets,
  referral,
  { acceptBetterOdds, acceptWorseOdds, noRiskBet, noDividends } = {}
) => {
  if (!bets || !bets.length) throw new Error("No bets");

  if (!referral) referral = "";
  let binOptions = 0;
  if (acceptBetterOdds) binOptions |= betOption.acceptBetterOdds;
  if (acceptWorseOdds) binOptions |= betOption.acceptWorseOdds;
  if (noRiskBet) binOptions ^= 1 << 2;
  if (noDividends) binOptions ^= 1 << 3;

  if (bets.length === 1)
    return `b|${referral}|${binOptions}|${encodeBet(bets[0])}|${encodeOdd(bets[0].odd)}`;

  return bets.reduce(
    (p, x) => p + `|${encodeBet(x)}|${encodeOdd(x.odd)}`,
    `p|${referral}|${binOptions}`
  );
};
