import React, { useState } from "react";

import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";
import ClientSideRenderer from "hoc/ClientSideRenderer.tsx";

type ColumnProps = {
  title: React.ReactNode;
  fields: Array<{ link: string; text: string }>;
};

type WidgetProps = {
  footerData: Array<ColumnProps>;
};

export const Widget: React.FC<WidgetProps> = ({ footerData }) => {
  const [expand, setExpand] = useState(false);

  if (!footerData?.length) return null;

  return (
    <div className={cx({ expanded: !!expand }, "footer-expand")}>
      <div className={cx("footer-column")}>
        {footerData.map((column, index: number) => (
          <div key={index} className="col-12 col-md-3 pt-1 pb-3">
            <div className="footer-group-label mb-2">{column.title}</div>
            {!!column?.fields.length &&
              column.fields.map((field, index) => (
                <a key={index} href={field.link} className="nav-link">
                  {field.text || ""}
                </a>
              ))}
          </div>
        ))}
      </div>
      <div className="footer-expand__button-wrapper">
        <div className="footer-expand__button" onClick={() => setExpand(!expand)}>
          <ClientSideRenderer>
            <FontAwesomeIcon icon={expand ? faChevronUp : faChevronDown} />
          </ClientSideRenderer>
        </div>
      </div>
    </div>
  );
};
