import { useMemo } from "react";
import Helmet from "react-helmet";
import SportDateFilter from "containers/sports/SportDateFilter";
import SportEvents from "containers/sports/SportEvents";
import SportFilter from "containers/sports/SportFilter";
import SportLiveStreamFilter from "containers/sports/SportLiveStreamFilter";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { getLiveSportsCount } from "selectors/eventSelectors";
import { getPageIds } from "selectors/pageSelectors";
import { getLiveSports } from "selectors/sportsSelectors";
import config from "config.json";
import { sports } from "constants/sports";

const { domain } = config;

interface Params {
  type?: string;
  league?: string;
  game?: string;
  sport?: string;
}

interface Sport {
  _id: number;
}

interface LiveCounts {
  [key: number]: number;
}

interface PageIds {
  sportId?: number;
}

const LiveSportsHomepage: React.FC<{ league: string; game: string }> = ({
  league,
  game,
}) => {
  const liveCounts = useSelector(getLiveSportsCount) as LiveCounts;
  const liveSports = useSelector<any, Sport[]>(getLiveSports);
  const sportId = liveSports.find(({ _id }) => !!liveCounts[_id])?._id || sports.soccer;

  return <SportEvents sportId={sportId} live={true} league={league} game={game} />;
};

const SportPage: React.FC = () => {
  const params = useParams<Params>();
  const { type, league, game, sport } = params;
  const { sportId } = useSelector<any, PageIds>(getPageIds);
  const live = type === "live";
  const location = useLocation();
  const isLiveHomePage = !!/\/live[/]?$/.exec(location.pathname);

  const canonicalUrl = useMemo(() => {
    if (live && sport && league) return `https://${domain}/sports/${sport}/${league}`;
    if (live && sport) return `https://${domain}/live`;

    return null;
  }, [sport, league, live, domain]);

  return (
    <>
      {canonicalUrl && (
        <Helmet>
          <link rel="canonical" href={canonicalUrl} />
        </Helmet>
      )}
      <div className="main-container">
        <div className="d-flex justify-content-between align-items-center">
          {!live ? <SportDateFilter className="mr-2" /> : null}
          {live ? <SportLiveStreamFilter className="mr-2" /> : null}
          <SportFilter className="sport-filter" />
        </div>
        {isLiveHomePage ? (
          <LiveSportsHomepage league={league || ""} game={game || ""} />
        ) : (
          <SportEvents
            sportId={sportId || sports.soccer}
            game={game}
            league={league}
            live={live}
          />
        )}
      </div>
    </>
  );
};

export default SportPage;
