import {
  PTOKEN_ADDRESS_ERROR,
  PTOKEN_ADDRESS_LOADED,
  PTOKEN_ADDRESS_LOADING,
  WALLET_ADDRESSES_LOAD_ERROR,
  WALLET_ADDRESSES_LOADED,
  WALLET_ADDRESSES_LOADING,
  WALLET_BALANCES_LOAD_ERROR,
  WALLET_BALANCES_LOADED,
  WALLET_BALANCES_LOADING,
  WALLET_EXCHANGE_RATES,
  WALLET_EXCHANGE_RATES_CRYPTO,
  WALLET_EXCHANGE_RATES_FIAT,
  WALLET_SET_ACTIVE_CURRENCY,
  WALLET_SET_ACTIVE_TAB,
} from "actions/actionTypes";
import { changeCurrency } from "actions/currencyActions";
import { setShowBonus } from "actions/uiActions";
import getEosApi from "api/eosApi";
import { eosApiEstimatedFee } from "api/eosApi2";
import {
  fetchAccountAddresses,
  fetchAccountBalances,
  fetchCryptoExchangeRates,
  fetchExchangeRates,
  fetchFiatExchangeRates,
  fetchPTokenWalletAddress,
  fetchTokenWalletAddress,
} from "api/walletApi";
import {
  bonusCurrenciesOrder,
  currencies,
  currenciesOrderScatter,
  currenciesOrderSocial,
  normalizeToken,
} from "constants/currencies";
import { getEosAccount, getIsScatterUser } from "selectors/loginSelectors";
import { getWalletAddresses, getWalletBalances } from "selectors/walletSelectors";
import { getSavedCurrency } from "utils/browserUtils";
import { currencyAmountAvailable } from "utils/currencyUtils";

export const loadPTokenAddress = (currency) => async (dispatch, getState) => {
  const walletAddress = getEosAccount(getState());
  if (!walletAddress) return;
  dispatch({ type: PTOKEN_ADDRESS_LOADING });
  try {
    const address = await fetchPTokenWalletAddress(walletAddress, currency);
    dispatch({ type: PTOKEN_ADDRESS_LOADED, payload: { currency, address } });
  } catch (e) {
    dispatch({ type: PTOKEN_ADDRESS_ERROR, payload: e && e.message });
  }
};

export const loadTokenAddress = (currency) => async (dispatch, getState) => {
  const eosAccount = getEosAccount(getState());
  if (!eosAccount) return;
  dispatch({ type: PTOKEN_ADDRESS_LOADING });
  try {
    const token = normalizeToken(currency);

    const address = await fetchTokenWalletAddress({
      eosAccount,
      currency: currencies[token]?.name,
      provider: currencies[token]?.provider,
    });

    dispatch({ type: PTOKEN_ADDRESS_LOADED, payload: { currency, address } });
  } catch (e) {
    console.error(e);
    dispatch({ type: PTOKEN_ADDRESS_ERROR, payload: e && e.message });
  }
};

export const approveWithdraw = async (withdrawalData) => {
  return getEosApi().approveWithdrawal(withdrawalData);
};

export const rejectWithdraw = async (withdrawalData) => {
  return getEosApi().rejectWithdrawal(withdrawalData);
};

export const loadExchangeRates = () => async (dispatch) => {
  const data = await fetchExchangeRates();
  if (!data) return;
  const { fiat, crypto } = data;
  const rates = { fiat: {}, crypto: {} };
  Array.isArray(fiat) &&
    fiat.forEach((item) => {
      rates.fiat[item.currency] = item.rate;
    });
  Array.isArray(crypto) &&
    crypto.forEach((item) => {
      rates.crypto[item.currency] = item.rate;
    });

  dispatch({
    type: WALLET_EXCHANGE_RATES,
    payload: { rates },
  });
};

export const loadFiatExchangeRates = () => async (dispatch) => {
  const data = await fetchFiatExchangeRates();
  if (!data) return;
  const rates = {};
  data.forEach((item) => {
    rates[item.currency] = item.rate;
  });

  dispatch({
    type: WALLET_EXCHANGE_RATES_FIAT,
    payload: { rates },
  });
};

export const loadCryptoExchangeRates = () => async (dispatch) => {
  const data = await fetchCryptoExchangeRates();
  if (!data) return;
  const rates = {};
  data.forEach((item) => {
    rates[item.currency] = item.rate;
  });

  dispatch({
    type: WALLET_EXCHANGE_RATES_CRYPTO,
    payload: { rates },
  });
};

export const walletSetActiveCurrency = (currency) => ({
  type: WALLET_SET_ACTIVE_CURRENCY,
  payload: { currency },
});

export const walletSetActiveTab = (tab) => ({
  type: WALLET_SET_ACTIVE_TAB,
  payload: { tab },
});

export const walletLoadBalances = () => async (dispatch, getState) => {
  const accountName = getEosAccount(getState());
  const { loading } = getWalletBalances(getState());
  if (loading || !accountName) return;
  const isScatterUser = getIsScatterUser(getState());
  const currencies = isScatterUser ? currenciesOrderScatter : currenciesOrderSocial;
  dispatch({ type: WALLET_BALANCES_LOADING });
  try {
    const balances = await fetchAccountBalances(accountName);
    const selectedCurrency = getSavedCurrency();
    const basicCurrenciesAvailable = currencyAmountAvailable({ balances, currencies });
    const bonusCurrenciesAvailable = currencyAmountAvailable({
      balances,
      currencies: bonusCurrenciesOrder,
    });

    //select bonus as active currency if main currencies is empty
    if (balances && !basicCurrenciesAvailable.length && bonusCurrenciesAvailable.length) {
      dispatch(changeCurrency(bonusCurrenciesAvailable[0]));
      dispatch(setShowBonus(true));
    }
    //select main balance if bonus was selected, but bonus amount is empty now
    if (
      balances &&
      bonusCurrenciesOrder.includes(selectedCurrency ?? "") &&
      !bonusCurrenciesAvailable.length
    ) {
      dispatch(changeCurrency(basicCurrenciesAvailable[0] || currencies[0]));
      dispatch(setShowBonus(false));
    }
    dispatch({ type: WALLET_BALANCES_LOADED, payload: balances });
  } catch (e) {
    dispatch({
      type: WALLET_BALANCES_LOAD_ERROR,
      payload: "Cannot load balances",
    });
  }
};

export const walletLoadAddresses = () => async (dispatch, getState) => {
  const accountName = getEosAccount(getState());
  const { loading } = getWalletAddresses(getState());
  if (loading || !accountName) return;
  dispatch({ type: WALLET_ADDRESSES_LOADING });

  try {
    const addresses = await fetchAccountAddresses(accountName);
    dispatch({ type: WALLET_ADDRESSES_LOADED, payload: addresses });
  } catch (e) {
    dispatch({
      type: WALLET_ADDRESSES_LOAD_ERROR,
      payload: "Cannot load addresses",
    });
  }
};

export const loadEstimatedFee = async ({
  amount = 0,
  currencyCode = "",
  responseUnits,
  cb = () => {},
}) => {
  try {
    const token = normalizeToken(currencyCode);
    const { fee } = await eosApiEstimatedFee(
      amount,
      currencies[token]?.name,
      responseUnits
    );

    cb(fee);
  } catch (e) {
    console.error(e);
    return null;
  }
};
