import {
  LIVE_SPORT_MONITOR_START,
  LIVE_SPORT_MONITOR_STOP,
  SPORT_DATE_FILTER_SET,
  SPORT_FILTER_SET,
  SPORT_LOAD_ERROR,
  SPORT_LOADED,
  SPORT_LOADING,
  SPORT_MONITOR_START,
  SPORT_MONITOR_STOP,
  SPORT_SET_ACTIVE,
  SPORT_SET_LIVE_SPORTS_COUNT,
  SPORT_SET_LIVE_STREAM_FILTER,
} from "./actionTypes";
import { fetchEvents } from "api/eventsApi";
import { getError } from "api/api";
import { eventType } from "constants/events";

export const setSportFilter = (filter) => ({
  type: SPORT_FILTER_SET,
  payload: "" + filter,
});

export const setSportDateFilter = (filter) => ({
  type: SPORT_DATE_FILTER_SET,
  payload: "" + filter,
});

export const setSportLiveStreamFilter = (filter) => ({
  type: SPORT_SET_LIVE_STREAM_FILTER,
  payload: !!filter,
});

export const setLiveSportsCount = (data) => ({
  type: SPORT_SET_LIVE_SPORTS_COUNT,
  payload: data,
});

export const loadSport =
  (sportId, live, refresh = false) =>
  async (dispatch, getState) => {
    const sport = getState().sports.byId[sportId];
    const isVip = false; // todo: getAccountIsVip(getState());
    if (!sport) return;
    if (sport.updating) return;

    const incremental = !!sport.last;

    dispatch({ type: SPORT_LOADING, payload: { sportId, incremental, live } });
    try {
      const result = await fetchEvents({
        sport: sportId,
        since: live || refresh ? undefined : sport.last,
        type: live ? eventType.live : eventType.prematch,
        isVip,
      });
      result.live = live;
      dispatch({
        type: SPORT_LOADED,
        payload: result,
      });
      // request incremental snapshot immediately
      // if (!live && !result.incremental && getState().sports.byId[sportId].last)
      //  dispatch(loadSport(sportId, live));
    } catch (e) {
      const error = getError(e);
      dispatch({
        type: SPORT_LOAD_ERROR,
        payload: { sportId, error, live },
      });
    }
  };

export const monitorSport = (sportId, live) => ({
  type: live ? LIVE_SPORT_MONITOR_START : SPORT_MONITOR_START,
  payload: sportId,
});

export const monitorSportEnd = (sportId, live) => ({
  type: live ? LIVE_SPORT_MONITOR_STOP : SPORT_MONITOR_STOP,
  payload: sportId,
});

export const setActiveSport = (sportId = 0, live = false) => ({
  type: SPORT_SET_ACTIVE,
  payload: { sportId, live },
});
