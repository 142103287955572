import { CURRENCY_CHANGE_ERROR, CURRENCY_CHANGED, CURRENCY_CHANGING } from "actions/actionTypes";
import getEosApi from "api/eosApi";
import { currencies } from "constants/currencies";
import { getAccountUser } from "selectors/loginSelectors";

export const changeCurrency = (currencyName) => async (dispatch, getState) => {
  try {
    if (!currencies[currencyName]) {
      return;
    }
    dispatch({
      type: CURRENCY_CHANGING,
    });

    const user = getAccountUser(getState());
    if (user.provider !== "scatter" && !user.eosAccount) {
      dispatch({
        type: CURRENCY_CHANGED,
        payload: { currencyName, balance: 0 },
      });
      return;
    }

    const balance = await getEosApi().getAccountBalance(currencyName, user);

    dispatch({
      type: CURRENCY_CHANGED,
      payload: { currencyName, balance },
    });
  } catch (e) {
    dispatch({
      type: CURRENCY_CHANGE_ERROR,
      payload: e,
    });
  }
};
