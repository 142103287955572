import React, { useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import whitelabelUtils from "utils/whitelabelUtils.ts";
import {
  init,
  clearError,
  hideLoginPopup,
  hideSignUpPopup,
  loginWithToken,
} from "actions/loginActions.ts";
import { useDispatch, useSelector } from "react-redux";
import {
  getLoginError,
  getLoginPopupShown,
  getSignupPopupShown,
  getLoginProgress,
  getAccountUser,
} from "selectors/loginSelectors";
import ErrorPopup from "components/common/ErrorPopup";
import LoadingPopup from "components/common/Loaders/LoadingPopup";
import LoginPopup from "containers/popups/LoginPopup/LoginPopup";
import { localStorageUtils } from "utils/browserUtils";
import { useHistory, useParams } from "react-router";

const LoginHandler = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const accountUser = useSelector(getAccountUser);
  const popupShown = useSelector(getLoginPopupShown);
  const signupPopupShown = useSelector(getSignupPopupShown);
  const error = useSelector(getLoginError);
  const progress = useSelector(getLoginProgress);
  const resetError = useCallback(() => dispatch(clearError()), []);

  const closeLoginPopup = useCallback(() => {
    dispatch(hideLoginPopup());
    dispatch(clearError());
  }, [dispatch]);

  const closeSignUpPopup = useCallback(() => {
    dispatch(hideSignUpPopup());
    dispatch(clearError());
  }, [dispatch]);

  useEffect(() => {
    if (!accountUser) {
      try {
        const { token, social: isSocial } =
          JSON.parse(localStorageUtils.getItem("auth")) || {};
        if (isSocial && token) {
          dispatch(loginWithToken(token, history));
        } else if (whitelabelUtils.scatter()) {
          dispatch(init());
        }
      } catch (e) {
        localStorageUtils.removeItem("auth");
      }
    }
  }, []);

  useEffect(() => {
    if (popupShown) {
      // dispatch(hideLoginPopup());
      // dispatch(init());
    }
  }, [popupShown]);

  if (progress) {
    return <LoadingPopup>{t(progress)}</LoadingPopup>;
  }
  if (popupShown && (!error || error === "NO_EOS")) {
    return <LoginPopup onClose={closeLoginPopup} />;
  }
  if (signupPopupShown && (!error || error === "NO_EOS")) {
    return <LoginPopup signUp={true} onClose={closeSignUpPopup} />;
  }
  if (error) {
    return <ErrorPopup onClose={resetError}>{t(error)}</ErrorPopup>;
  }

  return null;
};

export default LoginHandler;
