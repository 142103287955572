import { currencies } from "constants/currencies";

export const isMBtc = (token) => !!["BTC", "PBTC", "SBTC"].includes(token);

export function getPossibleAccounts() {
  return Object.values(currencies).map((currency) => currency.code);
}

const getCurrency = (token) =>
  currencies[token] || { ...currencies.EOS, name: token, displayName: token };

export const getPrimaryLeagueOrderThreshold = (live) => (live ? 1000 : 500);

export function formatAmount(amount, token, caption = true, fullPrecision = false) {
  const displayName = getTokenDisplayName(token);
  const isFiat = ["USD", "EUR", "TRY", "ZAR"].includes(token);
  const displayAmount = isFiat
    ? amount !== "" && !Number.isNaN(+amount)
      ? (+amount).toFixed(2)
      : amount
    : formatTokenAmount(amount, token, false, fullPrecision);
  if (Number.isNaN(displayAmount))
    return `0.${isFiat ? "00" : isMBtc(token) ? "00000" : "0000"} ${displayName}`;
  return caption ? `${displayAmount} ${displayName}` : displayAmount;
}

export const tokenToFiat = (amount, token, currency, rates) => {
  if (
    !rates ||
    !rates.crypto ||
    !rates.fiat ||
    !rates.crypto[token] ||
    !rates.crypto.USDT ||
    !rates.fiat[currency]
  ) {
    return amount;
  }
  let result = amount;
  result *= rates.crypto[token]; // to EOS
  result /= rates.crypto.USDT; // to USDT
  result *= rates.fiat[currency];
  if (isMBtc(token)) result /= 1000;
  return result;
};

export const fiatToToken = (amount, token, currency, rates, isCasinoGame) => {
  if (
    !rates ||
    !rates.crypto ||
    !rates.fiat ||
    !rates.crypto[token] ||
    !rates.crypto.USDT ||
    !rates.fiat[currency]
  ) {
    return amount;
  }
  let result = amount;
  result /= rates.fiat[currency]; // to USD
  result *= rates.crypto.USDT; // to EOS
  result /= rates.crypto[token]; // to token
  if (isMBtc(token)) result *= 1000;
  if (isCasinoGame) {
    return result;
  }
  return Math.trunc(result * 10000) / 10000;
};

export function formatTokenAmount(
  amount,
  token,
  useShortPrecision = false,
  walletPrecision = false
) {
  const currency = getCurrency(token);
  const dAmount = currency.toDisplayValue(amount);
  const {
    fullDisplayPrecision,
    shortDisplayPrecision,
    walletDisplayPrecision,
    precision,
  } = currency;
  const displayPrecision = walletPrecision
    ? walletDisplayPrecision || fullDisplayPrecision
    : useShortPrecision
    ? shortDisplayPrecision
    : fullDisplayPrecision;
  const minValue = 5 * Math.pow(10, -precision - 1);

  return Math.abs(dAmount) > minValue
    ? (Math.trunc(dAmount * 10 ** displayPrecision) / 10 ** displayPrecision)
        .toFixed(displayPrecision)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")
    : Number(0).toFixed(displayPrecision);
}

export function getTokenDisplayName(token) {
  return getCurrency(token).displayName;
}

export const formatFiatAmount = (x, currency, locale = "en-US") => {
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  });

  return formatter.format(x);
};

export const convertToFiat = ({ amount, fiat, crypto, rates, precision = 0 }) => {
  if (
    !amount ||
    !rates ||
    !rates.crypto ||
    !rates.crypto[crypto] ||
    !rates.fiat ||
    !rates.fiat[fiat] ||
    !rates.crypto[currencies.USDT.name]
  ) {
    return 0;
  }

  const calculatedAmount =
    (amount * rates.crypto[crypto] * rates.fiat[fiat]) /
    rates.crypto[currencies.USDT.name];

  return calculatedAmount / Math.pow(10, precision);
};

export const currencyAmountAvailable = ({ balances, currencies }) => {
  if (
    !balances &&
    typeof balances !== "object" &&
    !currencies &&
    !Array.isArray(currencies)
  ) {
    return [];
  }
  const availableCurrencies = Object.entries(balances).reduce(
    (acc, [currency, amount]) => {
      if (amount) {
        acc.push(currency);
      }
      return acc;
    },
    []
  );
  return availableCurrencies.filter((currency) => currencies.includes(currency));
};
