import React from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router";
import { NavLink as Link } from "reactstrap";
import { NavLink as RRNavLink } from "react-router-dom";
import { isBrowser } from "utils/browserUtils";
import cx from "classnames";

const HashLink = ({ to, className = "", ...props }) => {
  const history = useHistory();
  const { pathname, hash } = useLocation();
  const active = hash === "#" + to;
  if (!isBrowser()) return null;
  return (
    <Link
      to={pathname + "#" + to}
      tag={RRNavLink}
      {...props}
      onClick={(e) => {
        e.preventDefault();
        history.push({ hash: to });
        if (typeof props.onClick === "function") {
          props.onClick(e);
        }
      }}
      className={cx(className, { "active-link": active })}
    />
  );
};

HashLink.propTypes = {
  to: PropTypes.string,
};

export default HashLink;
