import {
  addFavourite,
  addRecent,
  continueGame,
  deleteFavourite,
  endGame,
  fetchGame,
  fetchGameBySlug,
  fetchRelatedGames,
  prepareGame,
  refillCasinoGame,
  startGame,
  startGameDemo,
} from "actions/casino/api";
import {
  CASINO_GAME_DEMO_END,
  CASINO_GAME_DEMO_ERROR,
  CASINO_GAME_DEMO_STARTED,
  CASINO_GAME_DEMO_STARTING,
  CASINO_GAME_DEPOSIT_SHOWN_SET,
  CASINO_GAME_END,
  CASINO_GAME_ERROR,
  CASINO_GAME_LOADED,
  CASINO_GAME_LOADING,
  CASINO_GAME_REFILL_ERROR,
  CASINO_GAME_REFILL_PROGRESS,
  CASINO_GAME_REFILL_SHOWN_SET,
  CASINO_GAME_REFILLED,
  CASINO_GAME_RELATED_LOADED,
  CASINO_GAME_SET_ID,
  CASINO_GAME_STARTED,
  CASINO_GAME_STARTING,
} from "actions/casino/actionTypes";
import { getAccountCurrency, getEosAccount } from "selectors/loginSelectors";
import { getGameSessionId, getGameUserCurrency } from "selectors/casinoSelectors";
import { showLoginPopup, updateBalance } from "actions/loginActions.ts";
import { walletLoadBalances } from "actions/walletActions";
import { changeCurrency } from "actions/currencyActions";
import getEosApi from "api/eosApi";
import { sessionStorageUtils } from "utils/browserUtils";
import { isMBtc } from "utils/currencyUtils";

export const loadRelatedGames =
  (gameId, provider, limit = 4) =>
  async (dispatch, getState) => {
    const eosAccount = getEosAccount(getState());
    try {
      const data = await fetchRelatedGames(provider, eosAccount, limit);
      const relatedGames = Array.isArray(data)
        ? data.filter((x) => x._id !== gameId).slice(0, limit)
        : null;
      dispatch({
        type: CASINO_GAME_RELATED_LOADED,
        payload: relatedGames,
      });
    } catch (e) {
      // do nothing
    }
  };

export const setGameError = (error = "Game is not available") => ({
  type: CASINO_GAME_ERROR,
  payload: error,
});

export const loadGame = (gameId) => async (dispatch, getState) => {
  dispatch({ type: CASINO_GAME_LOADING });
  const eosAccount = getEosAccount(getState());
  try {
    const data = await fetchGame(gameId, eosAccount);
    if (!data.enabled) {
      dispatch(setGameError());
    } else {
      dispatch({ type: CASINO_GAME_LOADED, payload: data });
      dispatch(loadRelatedGames(gameId, data.provider));
    }
  } catch (e) {
    dispatch(setGameError());
  }
};

export const loadGameBySlug = (slug) => async (dispatch) => {
  dispatch({ type: CASINO_GAME_LOADING });

  try {
    const data = await fetchGameBySlug(slug);

    if (!data.enabled) {
      dispatch(setGameError());
    } else {
      dispatch({ type: CASINO_GAME_LOADED, payload: data });
      dispatch(loadRelatedGames(slug, data.provider));
      dispatch({
        type: CASINO_GAME_SET_ID,
        payload: data._id,
      });
    }
  } catch (e) {
    dispatch(setGameError());
  }
};

export const toggleFavourite = (gameId, isFavourite) => async (dispatch, getState) => {
  const eosAccount = getEosAccount(getState());
  if (!eosAccount) {
    dispatch(showLoginPopup());
    return;
  }

  if (isFavourite) {
    await deleteFavourite(gameId, eosAccount);
  } else {
    await addFavourite(gameId, eosAccount);
  }
};

export const startDemoPlay = (gameId, language) => async (dispatch) => {
  try {
    dispatch({ type: CASINO_GAME_DEMO_STARTING });
    const { url } = await startGameDemo({ gameId, language });
    dispatch({ type: CASINO_GAME_DEMO_STARTED, payload: url });
  } catch (e) {
    dispatch({ type: CASINO_GAME_DEMO_ERROR, payload: "Game cannot be started" });
  }
};

export const switchGameType = () => ({
  type: CASINO_GAME_DEPOSIT_SHOWN_SET,
  payload: true,
});

export const hideDeposit = () => ({
  type: CASINO_GAME_DEPOSIT_SHOWN_SET,
  payload: false,
});

export const endGameDemo = () => ({
  type: CASINO_GAME_DEMO_END,
});

export const trackRecent = (gameId, eosAccount) => async () => {
  try {
    await addRecent(gameId, eosAccount);
  } catch (e) {
    // do something
  }
};

export const playGame =
  ({ gameId, language, amount, currency, rate, userAmount, userCurrency }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: CASINO_GAME_STARTING });
      const eosAccount = getEosAccount(getState());
      dispatch(trackRecent(gameId, eosAccount));
      const { eos_id } = await prepareGame({
        game_uuid: gameId,
        account: eosAccount,
        language,
      });
      const trx = await getEosApi().casinoDeposit(userAmount, userCurrency, eos_id);
      const { _id, url } = await startGame({
        eos_id,
        currency: isMBtc(currency) ? "mBTC" : currency === "EOS" ? "mEOS" : currency,
        amount: isMBtc(currency) ? amount * 1000 : amount,
        rate,
        userCurrency,
        trx,
      });
      dispatch({
        type: CASINO_GAME_STARTED,
        payload: {
          sessionId: _id,
          gameUrl: url,
          currency,
          rate,
          userCurrency,
          eosId: eos_id,
        },
      });
      dispatch(updateBalance());
      dispatch(walletLoadBalances());
    } catch (e) {
      dispatch({ type: CASINO_GAME_DEMO_ERROR, payload: "Game cannot be started" });
    }
  };

export const refillGame =
  ({ sessionId, userAmount, userCurrency, eosId }) =>
  async (dispatch) => {
    try {
      dispatch({ type: CASINO_GAME_REFILL_PROGRESS, payload: true });
      // const eosAccount = getEosAccount(getState());
      const trx = await getEosApi().casinoDeposit(userAmount, userCurrency, eosId);
      await refillCasinoGame({
        sessionId,
        trx,
      });
      dispatch({ type: CASINO_GAME_REFILLED });
      dispatch(updateBalance());
    } catch (e) {
      dispatch({ type: CASINO_GAME_REFILL_ERROR, payload: "Game cannot be refilled" });
    } finally {
      dispatch({ type: CASINO_GAME_REFILL_PROGRESS, payload: false });
    }
  };

export const setRefillShown = (shown) => (dispatch, getState) => {
  if (shown) {
    const currency = getGameUserCurrency(getState());
    const activeCurrency = getAccountCurrency(getState());
    if (currency !== activeCurrency) {
      dispatch(changeCurrency(currency));
    }
  }
  dispatch({
    type: CASINO_GAME_REFILL_SHOWN_SET,
    payload: shown,
  });
};

export const endCasinoGame = () => async (dispatch, getState) => {
  try {
    const sessionId = getGameSessionId(getState());
    if (sessionId) {
      await endGame(sessionId);
    }
  } catch (e) {
    // do nothing
  } finally {
    dispatch({ type: CASINO_GAME_END });
    dispatch(updateBalance());
  }
};

export const startFunPlayInsteadOfReal = (gameId, language) => async (dispatch) => {
  await dispatch(endCasinoGame());
  await dispatch(startDemoPlay(gameId, language));
};

export const continueGameSession =
  ({ gameId, sessionId }) =>
  async (dispatch) => {
    try {
      dispatch({ type: CASINO_GAME_STARTING });
      // const eosAccount = getEosAccount(getState());
      const { url, currency, rate, userCurrency } = await continueGame({
        gameId,
        sessionId,
      });
      dispatch({
        type: CASINO_GAME_STARTED,
        payload: {
          sessionId,
          gameUrl: url,
          currency,
          rate,
          userCurrency,
          realPlay: true,
          depositShown: false,
          menuEnabled: true,
        },
      });
    } catch (e) {
      dispatch({ type: CASINO_GAME_DEMO_ERROR, payload: null });
      try {
        sessionStorageUtils.removeItem("gameSession");
      } catch (e) {
        // do nothing
      }
    }
  };
