import EOS from "../images/tokens/EOS.svg";
import SBET from "../images/tokens/SBET.svg";
import PBTC from "../images/tokens/PBTC.svg";
import PLTC from "../images/tokens/PLTC.svg";
import PETH from "../images/tokens/PETH.svg";
import USDT from "../images/tokens/USDT.svg";

export const tokenIcons = {
  EOS,
  SBET,
  PBTC,
  PLTC,
  PETH,
  USDT,
  mBTC: PBTC,
  BTC: PBTC,
  LTC: PLTC,
  ETH: PETH,
  SEOS: EOS,
  SBTC: PBTC,
  SLTC: PLTC,
  SETH: PETH,
  SUSD: USDT,
  ["TRX:USDT"]: USDT,
};
