import api from "api/api";
import { normalizeBet } from "utils/projectUtils";

export const fetchBets = async (account, status, since) => {
  const params = { account, status, since };
  const resp = await api.get("/bets", { params });
  const result = resp.data;
  result.bets = result.bets && result.bets.map(normalizeBet);
  return result;
};

export const fetchBet = async (betId) => {
  const { data } = await api.get(`/bets/${betId}`);
  return data;
};

export const fetchBetLimits = async () => {
  const { data } = await api.get("/bets/limits");
  return data;
};
