import { getUserStats } from "selectors/userStatsSelectors";
import { USER_STATS_ERROR, USER_STATS_LOADED, USER_STATS_LOADING } from "actions/actionTypes";
import { getLoginToken } from "selectors/loginSelectors";
import { getUserStats as getUserStatsApi } from "api/userStatsApi";

export const loadUserStats = () => async (dispatch, getState) => {
  const { loading } = getUserStats(getState());
  if (loading) return;
  const token = getLoginToken(getState());
  dispatch({ type: USER_STATS_LOADING });

  try {
    const stats = await getUserStatsApi(token);
    dispatch({ type: USER_STATS_LOADED, payload: stats });
  } catch (e) {
    dispatch({
      type: USER_STATS_ERROR,
      payload: e?.toString(),
    });
  }
};
