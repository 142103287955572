import {
  BET_ACCEPT_CHANGES,
  BET_ADD,
  BET_AMOUNT_SET,
  BET_CANCEL,
  BET_CLEAR_ERROR,
  BET_CLEAR_PLACED,
  BET_ERROR,
  BET_LOAD,
  BET_OPTION_MODIFY,
  BET_PLACED,
  BET_PLACING,
  BET_PROMO_NO_RISK,
  BET_REMOVE,
  BET_REPLACE,
  BET_LIMITS,
} from "actions/actionTypes";
import {
  getBetAmount,
  getBetBets,
  getBetNoRiskBet,
  getBetOptions,
  getBetPlaced,
  getBetPlacing,
  getBetSlipBets,
} from "selectors/betSelectors";
import { getEvent } from "selectors/eventSelectors";
import { getExchangeRates } from "selectors/walletSelectors";
import {
  getAccountCurrency,
  getAccountUser,
  getEosAccount,
} from "selectors/loginSelectors";
import { getReferral, isBrowser, sessionStorageUtils } from "utils/browserUtils";
import { getViewCurrency } from "selectors/uiSelectors";
import { getError } from "api/api";
import getEosApi from "api/eosApi";
import { loadEvents } from "actions/eventActions";
import { login, updateBalance } from "actions/loginActions.ts";
import { getCloseUrl } from "actions/popupActions";
import { getBet as getLiveBet, toBetHash } from "utils/eventUtils";
import { getSimilarBet } from "utils/betUtils";
import { fiatToToken, isMBtc } from "utils/currencyUtils";
import { push } from "connected-react-router";
import { fetchBet, fetchBetLimits } from "../api/betsApi";

const doAddBet = (bet) => ({ type: BET_ADD, payload: bet });

const getCancelUrl = (getState) =>
  getCloseUrl(getState().router.location, ["/bet", "/betted"]);

export const addBet = (bet, history) => async (dispatch, getState) => {
  const user = getAccountUser(getState());
  if (!getEosAccount(getState())) {
    if (user && user.provider !== "scatter") return;
    await login({}, history)(dispatch, getState);
    if (!getEosAccount(getState())) return;
  }

  const inParlay = !!getBetBets(getState()).length;
  if (bet) dispatch(doAddBet(bet));

  if (!inParlay || !bet) {
    const betUrl = getCancelUrl(getState) + "/bet";
    dispatch(push(betUrl));
  }
};

export const removeBet = (bet) => ({ type: BET_REMOVE, payload: bet });
export const replaceBet = (remove, add) => ({
  type: BET_REPLACE,
  payload: { remove, add },
});
export const acceptChanges = () => async (dispatch) => {
  // dispatch(betError(null));
  dispatch({ type: BET_ACCEPT_CHANGES });
};

export const betCancel = () => ({ type: BET_CANCEL });

export const clearBetError = () => ({ type: BET_CLEAR_ERROR });

export const hideBet = (parlay) => async (dispatch, getState) => {
  const closeUrl = getCancelUrl(getState);
  dispatch(push(closeUrl));

  if (!parlay || getBetPlaced(getState())) dispatch({ type: BET_CANCEL });
};

export const setBetAmount = (hash, amount) => ({
  type: BET_AMOUNT_SET,
  payload: { hash, amount },
});

const betError = (error) => ({ type: BET_ERROR, payload: error });
const betPlacing = (msg) => ({ type: BET_PLACING, payload: msg });

export const refreshBets = () => async (dispatch, getState) => {
  const bets = getBetBets(getState());
  if (!bets || !bets.length) return;
  const eventIds = bets.map((x) => x.event);
  const newEvents = await dispatch(loadEvents(eventIds));

  // eslint-disable-next-line
  for (const bet of bets) {
    try {
      const newEvent = newEvents.find((x) => x._id === bet.event);
      let newBet = newEvent && getLiveBet(newEvent, bet);
      if (newBet) {
        dispatch(doAddBet(newBet));
      } else {
        newBet = newEvent && getSimilarBet(newEvent, bet);
        if (newBet) {
          dispatch(replaceBet(bet, newBet));
        }
      }
    } catch (e) {
      // do nothing
    }
  }
};

export const modifyBetOptions = (optionName, optionValue) => ({
  type: BET_OPTION_MODIFY,
  payload: {
    optionName,
    optionValue,
  },
});

const checkBetsAvailability = (dispatch, getState) => {
  const state = getState();
  const bets = getBetSlipBets(state);
  const betAmount = getBetAmount(state);
  const betOptions = getBetOptions(state);

  if (!bets || !bets.length) {
    throw new Error("The line, odds or availability of your selections has changed");
  }

  for (const bet of bets) {
    const betHash = toBetHash(bet);
    const amount = betAmount[betHash];
    if (!amount) continue;
    if (!bet.bet)
      throw new Error("The line, odds or availability of your selections has changed");
    const event = getEvent(state, bet.event);
    const liveBet = event && getLiveBet(event, bet);
    if (!liveBet || !liveBet.bet) {
      const newBet = event && getSimilarBet(event, bet);
      if (newBet) {
        dispatch(removeBet(bet));
        dispatch(doAddBet(newBet));
        dispatch(setBetAmount(betHash, ""));
        const newBetHash = toBetHash(newBet);
        dispatch(setBetAmount(newBetHash, ""));
      }

      throw new Error("The line, odds or availability of your selections has changed");
    }

    if (liveBet.odd < bet.odd) {
      dispatch(doAddBet(liveBet));
      if (!betOptions.acceptBetterOdds || !betOptions.acceptWorseOdds) {
        throw new Error("The line, odds or availability of your selections has changed");
      }
    }
  }
};
export const placeBet = () => async (dispatch, getState) => {
  try {
    const placing = getBetPlacing(getState());
    if (placing) return;
    dispatch(betPlacing());
    dispatch(acceptChanges());
    checkBetsAvailability(dispatch, getState);
    const state = getState();
    const bets = getBetSlipBets(state);
    const betAmount = getBetAmount(state);
    const token = getAccountCurrency(state);
    const betOptions = getBetOptions(state);
    const noRiskBet = getBetNoRiskBet(state);
    const account = getEosAccount(state);
    const user = getAccountUser(state);

    const referralOrig = getReferral(state);
    const viewCurrency = getViewCurrency(state);
    const rates = getExchangeRates(state);
    const isFiat =
      !!viewCurrency &&
      token !== viewCurrency &&
      ["USD", "EUR", "TRY"].includes(viewCurrency);

    if (user.provider !== "scatter" && !account) throw new Error("BALANCE_TOO_LOW");
    if (!account) throw new Error("Not Logged In");
    let referral = referralOrig;
    let noDividends = false;
    if (user.affiliate && user.affiliate.stag) {
      try {
        noDividends = true;
        referral = `sbet${user.affiliate.stag.split("_")[0]}`;
      } catch (e) {}
    }

    const trxData = [];
    const placedBet = [];
    for (const item of bets) {
      const { bet } = item;
      if (!bet.bet) continue;
      const betHash = toBetHash(bet);
      const amount = betAmount[betHash];
      if (!amount) continue;
      const options =
        noRiskBet === betHash ? { ...betOptions, noRiskBet: true } : { ...betOptions };
      if (user.affiliate && user.affiliate.stag) options.noDividends = true;
      const amountValue = isFiat
        ? fiatToToken(amount, token, viewCurrency, rates) * (isMBtc(token) ? 0.001 : 1)
        : amount;
      trxData.push({
        bets: [bet],
        amount: amountValue,
        options,
      });
      placedBet.push({
        bets: [item],
        amount: amountValue,
        options,
      });
    }
    const availableBets = bets.filter((x) => x.bet && x.bet.bet);
    if (availableBets.length > 1 && betAmount.parlay) {
      const amountValue = isFiat
        ? fiatToToken(betAmount.parlay, token, viewCurrency, rates) *
          (isMBtc(token) ? 0.001 : 1)
        : betAmount.parlay;

      trxData.push({
        bets: availableBets.map((x) => x.bet),
        amount: amountValue,
        options:
          noRiskBet === "parlay"
            ? { ...betOptions, noRiskBet: true, noDividends }
            : { ...betOptions, noDividends },
      });
      placedBet.push({
        bets: availableBets,
        amount: amountValue,
        options:
          noRiskBet === "parlay"
            ? { ...betOptions, noRiskBet: true, noDividends }
            : { ...betOptions, noDividends },
      });
    }

    if (!trxData.length) {
      throw new Error("No bets available");
    }

    let trxId = null;
    const result = await getEosApi().betsTransfer(
      user.eosAccount,
      trxData,
      referral,
      token
    );
    if (result && result.transactionId) {
      trxId = result.transactionId;
    } else {
      throw result;
    }
    const betIds = (result && result.betIds) || {};

    await new Promise((resolve) => {
      setTimeout(resolve, 2000);
    });

    for (const { bets } of placedBet) {
      if (bets?.length !== 1) continue;
      for (const { bet } of bets) {
        const betKey = [bet.event, bet.market, bet.side, bet.value]
          .filter((x) => !!x)
          .join("-");
        if (!betIds[betKey]) continue;
        let tries = 5;
        while (tries--) {
          try {
            const { bet: betInfo } = await fetchBet(betIds[betKey]);
            try {
              bets[0].bet = {
                ...bets[0].bet,
                odd: betInfo.legs[0].data.odd,
                value: betInfo.legs[0].data.value,
              };
            } catch (e) {
              // do nothing
            }
            break;
          } catch (e) {
            await new Promise((resolve) => {
              setTimeout(resolve, 1500);
            });
          }
        }
      }
    }

    if (betIds.parlay) {
      const parlayBet = placedBet.find((x) => x.bets.length > 0);
      if (parlayBet?.bets) {
        let tries = 5;
        while (tries--) {
          try {
            const { bet: betInfo } = await fetchBet(betIds.parlay);
            for (const betIndex in parlayBet.bets) {
              if (!betInfo.legs[betIndex]) break;
              parlayBet.bets[betIndex].bet = {
                ...parlayBet.bets[betIndex].bet,
                odd: betInfo.legs[betIndex].data.odd,
                value: betInfo.legs[betIndex].data.value,
              };
            }
            break;
          } catch (e) {
            await new Promise((resolve) => {
              setTimeout(resolve, 1500);
            });
          }
        }
      }
    }

    dispatch({
      type: BET_PLACED,
      payload: {
        bets: placedBet,
        riskFree: noRiskBet,
        trxId,
        betIds,
        token,
      },
    });
    sessionStorageUtils.removeItem("betslip");
    dispatch({ type: BET_PROMO_NO_RISK, payload: null });
    await dispatch(updateBalance());
  } catch (e) {
    dispatch(refreshBets());
    const error = getError(e);
    dispatch(betError(error));
    // checkMaxBet(token, error, dispatch);
  }
};

export const betClearPlaced = () => ({
  type: BET_CLEAR_PLACED,
});

export const toggleNoRiskBet = (hash) => ({
  type: BET_PROMO_NO_RISK,
  payload: hash,
});

export const restoreBetSlip = () => (dispatch) => {
  if (!isBrowser()) return;
  try {
    const betslipData = sessionStorageUtils.getItem("betslip");
    if (!betslipData) return;
    const betslip = JSON.parse(betslipData);
    if (!betslip);
    dispatch({ type: BET_LOAD, payload: { ...betslip } });
    dispatch(refreshBets());
  } catch (e) {
    // do nothing
  }
};

export const repeatBetSlip = () => (dispatch, getState) => {
  if (!isBrowser()) return;
  const placed = getBetPlaced(getState());
  if (placed && placed.bets) {
    const parlay = placed.bets.find((x) => x.bets.length > 1);
    if (parlay) {
      dispatch({
        type: BET_LOAD,
        payload: {
          bets: parlay.bets.map((x) => x.bet),
          betAmount: {},
          noRiskBet: null,
        },
      });
    } else {
      let bets = [];
      for (const bet of placed.bets) {
        bets = [...bets, ...bet.bets.map((x) => x.bet)];
      }
      dispatch({
        type: BET_LOAD,
        payload: {
          bets: bets,
          betAmount: {},
          noRiskBet: null,
        },
      });
    }
  }
};

export const getBetLimits = () => async (dispatch) => {
  const result = await fetchBetLimits();

  if (result) {
    dispatch({ type: BET_LIMITS, payload: result });
  }
};
