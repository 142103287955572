import BrowserOnly from "components/common/BrowserOnly.tsx";
import BetSlipHandler from "containers/BetSlipHandler/BetSlipHandler";
import LoginHandler from "containers/LoginHandler/LoginHandler";
import NotificationsOnsite from "containers/NotificationsOnsite/NotificationsOnsite";
import TfaConfirm from "containers/popups/TfaConfirm/TfaConfirm";
import Unsubscribe from "containers/popups/Unsubscribe/Unsubscribe";
import React, { Suspense, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Loadable from "react-loadable";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch, useParams } from "react-router-dom";
import "typeface-roboto";
import "./styles/index.scss";
import "./pages/LandingPage/styles.module.scss";
import LanguageDetector from "i18next-browser-languagedetector";
import initI18n from "config/i18n";
import HashRoutes from "routes/HashRoutes";
import App from "./App";
import configureStore from "./config/store";
import startMonitoring from "utils/monitoring";
import qs from "query-string";
import { localStorageUtils, sessionStorageUtils } from "utils/browserUtils";
import ErrorPopup from "components/common/ErrorPopup";
import LicenseAgreement from "containers/LicenseAgreement";
import Cookies from "js-cookie";
import { xorDecrypt } from "utils/encryptUtils";

initI18n(LanguageDetector);

const initialState = JSON.parse(xorDecrypt(window.__REDUX_STATE__)) || {};
if (initialState.ui) {
  initialState.ui.viewFiat = !!+localStorage.getItem("fiat_view");
}
if (initialState.settings) {
  initialState.settings.fiatCurrency = localStorageUtils.getItem("fiat_currency") || null;
}
export const store = configureStore(initialState);
startMonitoring(store);

const AuthHandler = () => {
  const [authError, setAuthError] = useState(null);
  let error = null;

  try {
    const token = Cookies.get("token");
    if (token) {
      Cookies.remove("token", { path: "/", domain: ".sportbet.one" });
      localStorageUtils.setItem(
        "auth",
        JSON.stringify({ token: decodeURIComponent(token), social: true })
      );
      window.history.replaceState(
        {},
        document.title,
        window.location.href.replace(/\?.+$/, "")
      );
    }
  } catch (e) {
    console.log(e);
  }

  useEffect(() => {
    setAuthError(error);
  }, [error]);

  if (authError) {
    return (
      <ErrorPopup
        title="Sign In"
        onClose={() => {
          window.history.replaceState(
            {},
            document.title,
            window.location.href.replace(/\?.+$/, "")
          );
          setAuthError(null);
        }}
        errorMessage={authError}
      />
    );
  }

  return null;
};

const ActivateAccount = () => {
  const { type, code } = useParams();
  const { email, contact } = qs.parse(window.location.search);
  sessionStorageUtils.setItem("activationType", type);
  sessionStorageUtils.setItem("activationCode", code);
  sessionStorageUtils.setItem("activationEmail", email);
  if (contact === "1") {
    sessionStorageUtils.setItem("activateContactEmail", 1);
  }
  if (!type || !code || !email) return <Redirect to={"/sports/soccer"} />;
  return <Redirect to={`/sports/soccer#activate`} />;
};

const AppBundle = (
  <ReduxProvider store={store}>
    <BrowserRouter>
      <Suspense fallback={<div className="page-loading">Loading...</div>}>
        <Switch>
          <Route path={`/activate/:type?/:code?`} component={ActivateAccount} />
        </Switch>
        <BrowserOnly>
          <LoginHandler />
          <HashRoutes />
          <BetSlipHandler />
          <AuthHandler />
          <LicenseAgreement />
          <Unsubscribe />
          <TfaConfirm />
          <NotificationsOnsite />
        </BrowserOnly>
        <App />
      </Suspense>
    </BrowserRouter>
  </ReduxProvider>
);

window.onload = () => {
  Loadable.preloadReady().then(() => {
    ReactDOM.render(AppBundle, document.getElementById("root"));
  });
};
