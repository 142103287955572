import SkeletonLoader from "components/common/Loaders/Skeleton.tsx";
import React from "react";

export const TableLoader = () => {
  return (
    <div className="d-flex flex-column w-100">
      {Array(10)
        .fill(null)
        .map((el, idx) => (
          <SkeletonLoader
            key={`${idx}`}
            width={"100%"}
            className="mt-2"
            viewBox="0 0 100 48"
            preserveAspectRatio="none"
          />
        ))}
    </div>
  );
};
