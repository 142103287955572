import React from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import whitelabelUtils from "utils/whitelabelUtils.ts";
import { removeSuffix } from "utils/stringUtils";

function xmur3(str) {
  for (var i = 0, h = 1779033703 ^ str.length; i < str.length; i++) {
    h = Math.imul(h ^ str.charCodeAt(i), 3432918353);
    h = (h << 13) | (h >>> 19);
  }
  return function () {
    h = Math.imul(h ^ (h >>> 16), 2246822507);
    h = Math.imul(h ^ (h >>> 13), 3266489909);
    return (h ^= h >>> 16) >>> 0;
  };
}

export const prepare = (id, title) => {
  try {
    const variables = [...title.matchAll(/\{\{.+?\}\}/g)];
    let titleStr = title;
    for (const variable of variables) {
      if (!variable["0"]) continue;
      const varStr = variable["0"].replace(/^\{\{(.+)\}\}$/, "$1");
      const varValues = varStr.split("/");
      const r = xmur3(id);
      const r1 = r();
      const rn = Math.max(
        0,
        Math.min(
          varValues.length,
          r1 - Math.floor(r1 / varValues.length) * varValues.length
        )
      );
      const varValue = varValues[rn];
      titleStr = titleStr.replace(variable["0"], varValue);
    }
    return titleStr;
  } catch (e) {
    return title;
  }
};

const PageMeta = ({
  id,
  title = "",
  description = "",
  isGameListPage,
  paginationPage,
  isGame,
}) => {
  const { i18n } = useTranslation();
  const { pathname, search } = useLocation() || {};
  let currentPath = pathname.replace("/" + i18n.language + "/", "/").replace("//", "/");
  if (currentPath === "/" || currentPath === "/" + i18n.language) {
    currentPath = "";
  }
  const canonical = whitelabelUtils.canonical().find((e) => e.from === currentPath);
  const isAlternate = search.includes("alternate=true");

  return (
    <Helmet>
      {!!title &&
        (isGameListPage && paginationPage ? (
          <title>{`Page ${paginationPage} ᐈ ${prepare(id, title)}`}</title>
        ) : (
          <title>{prepare(id, title)}</title>
        ))}
      {!!description && (
        <meta name="description" content={prepare(id + "2", description)} />
      )}
      {canonical && canonical.to && <link rel="canonical" href={canonical.to} />}
      {!canonical && !isGameListPage && !isGame && i18n.language === "en" && (
        <link rel="canonical" href={`https://sportbet.one${currentPath}`} />
      )}
      {!!isGame &&
        (currentPath.endsWith("-mobile") ? (
          <link
            rel="canonical"
            href={`https://sportbet.one${removeSuffix(currentPath, "-mobile")}`}
          />
        ) : (
          <link rel="canonical" href={`https://sportbet.one${currentPath}`} />
        ))}
      <link
        rel="alternate"
        href={`https://sportbet.one${currentPath}`}
        hrefLang={whitelabelUtils.defaultLanguage()}
      />
      {(i18n.language !== whitelabelUtils.defaultLanguage() || isAlternate) && (
        <meta name="robots" content="noindex" />
      )}
    </Helmet>
  );
};

export default PageMeta;
