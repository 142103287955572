import Loadable from "react-loadable";
import PromoGrid from "./Grid";
import Loading from "components/common/Loaders/Loading.tsx";

const LazyPromoCards = Loadable({
  loader: () => import(/* webpackChunkName: "promo-cards"*/ "./PromoCards"),
  loading: Loading,
  modules: ["promo-cards"],
});

export default PromoGrid;
export { LazyPromoCards };
