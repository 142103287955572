import React from "react";
import { Button } from "reactstrap";
import { SuccessMessageProps } from "containers/popups/LoginPopup/model/types";

export const SuccessMessage: React.FC<SuccessMessageProps> = ({
  message,
  icon,
  onClose,
}) => {
  return (
    <div className={"login-form text-center"} style={{ marginTop: 0 }}>
      <div className={"login-message"}>
        {icon ? <img src={icon} style={{ margin: "0 0 18px 0" }} /> : null}
        <p>{message}</p>
      </div>
      <Button
        className="mt-2 rounded btn-success"
        style={{ width: "auto", padding: "0 48px", maxWidth: "100%" }}
        onClick={onClose}
      >
        Ok
      </Button>
    </div>
  );
};
