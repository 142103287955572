import { getGameListParams, getIsReloadRequired } from "selectors/casinoSelectors";
import {
  CASINO_GAMELIST_CATEGORY_SET,
  CASINO_GAMELIST_CHANGE_ACTIVE_PAGES,
  CASINO_GAMELIST_CHANGE_PAGE,
  CASINO_GAMELIST_IS_RELOAD_REQUIRED,
  CASINO_GAMELIST_LOAD_ERROR,
  CASINO_GAMELIST_LOADING,
  CASINO_GAMELIST_PAGE_SET,
  CASINO_GAMELIST_PARAMS_INIT,
  CASINO_GAMELIST_PROVIDERS_SET,
  CASINO_GAMELIST_SEARCH_SET,
  CASINO_GAMELIST_SORT_BY_SET,
} from "actions/casino/actionTypes";
import { fetchGames } from "actions/casino/api";
import { isMobile } from "react-device-detect";
import { getEosAccount } from "selectors/loginSelectors";

const allCategory = "all-games";
const favouriteCategory = "favourite";
const recentCategory = "recent";

export const initGameListParams = (queryParams, matchParams) => (dispatch, getState) => {
  const state = getState();
  const stateParams = getGameListParams(state);
  const params = {};

  const currentPageCategory =
    matchParams?.categoryWithAltCoin?.replace(/^[^,]+- */, "") ?? matchParams?.category;

  if (queryParams.providers) {
    const providers = queryParams.providers ? queryParams.providers.split(",") : [];
    if (providers && providers.length) {
      params.providers = providers;
    }
  } else if (matchParams.provider) {
    params.providers = [matchParams.provider];
  } else {
    params.providers = [];
  }
  if (queryParams.sort) {
    params.sortBy = queryParams.sort;
  } else {
    params.sortBy = "name";
  }
  if (queryParams.search) {
    params.search = queryParams.search;
  } else {
    params.search = "";
  }
  if (currentPageCategory) {
    params.category = currentPageCategory;
  } else {
    params.category = null;
  }
  if (+queryParams.page > 0) {
    params.page = +queryParams.page;
  } else {
    params.page = 1;
  }
  if (stateParams.activePages.length === 0 && +queryParams.page) {
    params.activePages = [+queryParams.page];
  }
  if (!params.activePages && !+queryParams.page) {
    params.activePages = [1];
  }

  dispatch({
    type: CASINO_GAMELIST_PARAMS_INIT,
    payload: params,
  });
};

export const changeGameListCategory = (category) => ({
  type: CASINO_GAMELIST_CATEGORY_SET,
  payload: category,
});

export const changeGameListPage = (page) => ({
  type: CASINO_GAMELIST_PAGE_SET,
  payload: page,
});

export const changeGameListSortBy = (sortBy) => ({
  type: CASINO_GAMELIST_SORT_BY_SET,
  payload: sortBy,
});

export const changeGameListSearch = (search) => ({
  type: CASINO_GAMELIST_SEARCH_SET,
  payload: search,
});

export const changeGameListProviders = (providers) => ({
  type: CASINO_GAMELIST_PROVIDERS_SET,
  payload: providers,
});

export const changePaginationActivePages = (pages) => ({
  type: CASINO_GAMELIST_CHANGE_ACTIVE_PAGES,
  payload: pages,
});

export const setIsReloadRequired = (reloadRequired) => ({
  type: CASINO_GAMELIST_IS_RELOAD_REQUIRED,
  payload: reloadRequired,
});

export const loadGameList = () => async (dispatch, getState) => {
  dispatch({ type: CASINO_GAMELIST_LOADING });
  let state = getState();
  const params = getGameListParams(state);
  const account = getEosAccount(state);
  const queryParams = {};
  queryParams.page = params.page || 1;
  queryParams.perPage = params.perPage || 12;
  queryParams.sortBy = params.sortBy || "name";
  if (params.category === favouriteCategory) {
    queryParams.favourite = 1;
  } else if (params.category === recentCategory) {
    queryParams.recent = 1;
  } else if (params.category !== allCategory) {
    queryParams.category = params.category;
  }
  if (params.providers && params.providers.length) {
    queryParams.providers = params.providers.join(",");
  }
  if (params.search) {
    queryParams.search = params.search;
  }
  if (account) {
    queryParams.userId = account;
  }
  if (params.page) {
    queryParams.page = params.page;
  }
  if (isMobile) {
    queryParams.mobile = 1;
  }

  try {
    const data = await fetchGames(queryParams);
    state = getState();
    if (getIsReloadRequired(state)) {
      dispatch(setIsReloadRequired(false));
      dispatch(loadGameList());
      return;
    }

    dispatch({
      type: CASINO_GAMELIST_CHANGE_PAGE,
      payload: { ...data, type: params.type },
    });
    dispatch({
      type: CASINO_GAMELIST_PARAMS_INIT,
      payload: { type: null },
    });
  } catch (e) {
    dispatch({ type: CASINO_GAMELIST_LOAD_ERROR, payload: e && e.message });
  }
};
