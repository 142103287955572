import {
  CLEAR_EVENT,
  EVENT_BREADCRUMBS_LOADED,
  EVENT_BREADCRUMBS_RESET,
  EVENT_LOAD_ERROR,
  EVENT_LOADED,
  EVENT_LOADING,
  EVENT_MONITOR_START,
  EVENT_MONITOR_STOP,
  EVENTS_LOAD_ERROR,
  EVENTS_LOADED,
  EVENTS_LOADING,
  HOMEPAGE_SPORT_LOADED,
  SPORT_LOADED,
} from "actions/actionTypes";
import { combineReducers } from "redux";
import { normalizeEvent } from "utils/projectUtils";
import { monitorReducer } from "utils/reduxUtils";
import { eventType } from "constants/events";

function byIdReducer(state = {}, action) {
  switch (action.type) {
    case HOMEPAGE_SPORT_LOADED: {
      const { prematchEvents, liveEvents, sport } = action.payload;
      const newState = { ...state };
      prematchEvents.forEach((x) => {
        const event = normalizeEvent(x, x.sp, false);
        newState[event._id] = event;
      });

      liveEvents.forEach((x) => {
        const event = normalizeEvent(x, x.sp, false);
        newState[event._id] = event;
      });

      return newState;
    }
    case SPORT_LOADED: {
      const { events, removed, incremental, sport, live } = action.payload;
      const hasChanges = !incremental || events.length !== 0 || removed.length !== 0;
      if (!hasChanges) return state;

      const newState = { ...state };
      if (!incremental) {
        const type = live ? eventType.live : eventType.prematch;
        // remove all old sport events
        Object.getOwnPropertyNames(newState).forEach((id) => {
          if (newState[id].sport === sport && newState[id].type === type)
            delete newState[id];
        });
      }
      removed.forEach((id) => {
        if (newState[id]) delete newState[id];
      });

      events.forEach((x) => {
        newState[x._id] = x;
      });
      return newState;
    }
    case EVENTS_LOADED: {
      const { events, removed } = action.payload;
      const newState = { ...state };
      removed.forEach((id) => {
        if (newState[id]) delete newState[id];
      });

      events.forEach((x) => {
        newState[x._id] = x;
      });
      return newState;
    }
    default:
      return state;
  }
}

function loadingReducer(state = {}, action) {
  switch (action.type) {
    case EVENTS_LOADING: {
      const { payload } = action;
      const newState = { ...state };
      payload.forEach((x) => {
        newState[x] = { ...newState[x], loading: true };
      });
      return newState;
    }
    case EVENTS_LOADED: {
      const { events, removed } = action.payload;
      const newState = { ...state };
      removed.forEach((x) => {
        newState[x] = { notFound: true, loading: false };
      });
      events.forEach((x) => {
        newState[x._id] = { loading: false };
      });
      return newState;
    }
    case EVENTS_LOAD_ERROR: {
      const { eventIds, error } = action.payload;
      const newState = { ...state };
      eventIds.forEach((x) => {
        newState[x] = { error, loading: false };
      });
      return newState;
    }
    default:
      return state;
  }
}

const eventReducer = (state = {}, action) => {
  switch (action.type) {
    case EVENT_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case EVENT_LOADED:
      return {
        ...state,
        loading: false,
        event: action.payload,
        error: null,
      };
    case EVENT_LOAD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case CLEAR_EVENT:
      return {
        error: null,
        loading: false,
        event: null,
      };
    default:
      return state;
  }
};

const breadcrumbs = (state = null, action) => {
  switch (action.type) {
    case EVENT_BREADCRUMBS_LOADED:
      return { ...action.payload.breadcrumbs };
    case EVENT_BREADCRUMBS_RESET:
      return null;
    default:
      return state;
  }
};

const monitoringReducer = monitorReducer(EVENT_MONITOR_START, EVENT_MONITOR_STOP);

const eventsReducer = combineReducers({
  byId: byIdReducer,
  loading: loadingReducer,
  monitoring: monitoringReducer,
  breadcrumbs,
  eventById: eventReducer,
});

export default eventsReducer;
