import { FC, useState } from "react";
import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import ClientSideRenderer from "hoc/ClientSideRenderer.tsx";
import parse from "html-react-parser";

const TextExpander: FC<{ html: string }> = ({ html }) => {
  const [expand, setExpand] = useState(false);

  return (
    <div className={cx({ expanded: !!expand }, "text-expand")}>
      {!html.includes("<!-- HTML -->") ? (
        <div className="text-expand__content">{parse(html)}</div>
      ) : (
        <div
          className="text-expand__content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      )}
      <div className="text-expand__button-wrapper">
        <div className="text-expand__button" onClick={() => setExpand(!expand)}>
          <ClientSideRenderer>
            <FontAwesomeIcon icon={expand ? faChevronUp : faChevronDown} />
          </ClientSideRenderer>
        </div>
      </div>
    </div>
  );
};

export default TextExpander;
