import React from "react";
import { useTranslation } from "react-i18next";
import { LoadingComponentProps } from "react-loadable";
import { Alert, Spinner } from "reactstrap";

type Props = {
  message?: string;
  className?: string;
};

const AsyncLoading: React.FC<LoadingComponentProps & Props> = ({
  message = "Loading...",
  className,
}) => {
  const { t } = useTranslation();

  return (
    <Alert color="dark" className={className}>
      {t(message)} <Spinner size="sm" />
    </Alert>
  );
};

export default AsyncLoading;
