export const casinoPaginationChangeType = {
  add: "add",
  set: "set",
};

export const casinoCategories = [
  "favourite",
  "recent",
  "roulette",
  "slots",
  "live-casino",
  "table-games",
  "card-games",
  "provably-fair",
  "instant",
  "blackjack",
  "baccarat",
  "poker",
  "dice",
  "plinko",
  "keno",
];

export const casinoAltCoins = ["ethereum", "tether", "eos", "litecoin", "usdt"];

export const categoriesWithCoins = casinoAltCoins.reduce((a, v) => {
  const newCategories = casinoCategories.map((c) => `${v}-${c}`);

  return [...a, ...newCategories];
}, []);
