import React, { useEffect, useMemo } from "react";
import { Redirect } from "react-router";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { getEventById } from "selectors/eventSelectors";
import { loadEventById } from "actions/eventActions";
import { getEventUrl, getUrl } from "utils/urlUtils";
import { CLEAR_EVENT } from "actions/actionTypes";

const HashEventHandler: React.FC<{ queryParams: string }> = ({ queryParams = "" }) => {
  const dispatch = useDispatch();
  const { event, loading } = useSelector(getEventById);
  const { id } = queryString.parse(queryParams);

  useEffect(() => {
    if (id) dispatch(loadEventById(id, { leagueSlug: 1, slug: 1, sport: 1, type: 1 }));

    return () => dispatch({ type: CLEAR_EVENT });
  }, [dispatch, id]);

  const eventUrl = useMemo(() => {
    if (event && !loading && !event.errorMessage) return getUrl(getEventUrl(event));

    return null;
  }, [event, loading]);

  if (!eventUrl) return null;
  return <Redirect to={eventUrl} />;
};

export default HashEventHandler;
