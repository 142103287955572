import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getAccountName } from "selectors/loginSelectors";
import useIsLoggedIn from "./useIsLoggedIn.ts";

const usePromoVisible = () => {
  const account = useSelector(getAccountName);

  const [promoVisible, setPromoVisible] = useState(!useIsLoggedIn());

  useEffect(() => {
    const visible = !useIsLoggedIn();
    if (visible !== promoVisible) {
      setPromoVisible(visible);
    }
  }, [account]);

  return promoVisible;
};

export default usePromoVisible;
