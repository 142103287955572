import Accordion from "components/data/Accordion";
import React from "react";
import { useLocation } from "react-router-dom";

const Faq = ({ title, sections }) => {
  // FIXME: add param to block with anchor
  const { hash } = useLocation();
  const isEosFork = hash === "#eos-fork";

  return (
    <div className={"faq-content"}>
      {title ? <h2>{title}</h2> : null}
      {sections.map((x, i) => (
        <div
          className="my-2"
          key={`faq-${i}`}
          itemScope
          itemProp="mainEntity"
          itemType="https://schema.org/Question"
        >
          <Accordion
            className={"accordion-faq"}
            header={
              <div className={"card-header-title"} itemProp="name">
                {x.q}
              </div>
            }
            bodyClass="p-2"
            startOpen={true}
            scrollTo={!isEosFork || x.q.includes("reversed")}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: `<div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer"><div itemProp="text">${x.a}</div></div>`,
              }}
            />
          </Accordion>
        </div>
      ))}
    </div>
  );
};

export default Faq;
