import axios from "axios";
import config from "../config.json";
import { CASINO_CLOSED } from "constants/general";
import { localStorageUtils } from "utils/browserUtils";
import q from "query-string";

export const apiType = {
  api: "api",
  serverApi: "serverApi",
};

const apiConfig = {
  timeout: config.apiTimeout,
  headers: { "Content-Type": "application/json" },
};

const setClientLanguage = (apiObj) => {
  try {
    if (typeof window !== "undefined") {
      const language = localStorage.getItem("i18nextLng");
      if (language) apiObj.defaults.headers["client-language"] = language;
    }
  } catch (e) {
    console.log(e);
  }
};

const setDefaultHeaders = (apiObj) => {
  if (typeof window !== "undefined") {
    const urlParams = q.parse(window.location.search);
    const keys = ["hitid", "stag", "subid"];
    const commonKeys = ["uid", "sid"];

    const resolve = (key) => {
      return localStorageUtils?.getItem(key) || urlParams[key];
    };
    keys.forEach((key) => {
      const value = resolve(key);
      if (value) apiObj.defaults.headers[key] = value;
    });
    commonKeys.forEach((key) => {
      const value = resolve(key);
      if (value) apiObj.defaults.headers.common[key] = value;
    });
  }
};

const setInterseptors = (apiObj) => {
  apiObj.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      return {
        data: {
          ...(error.response?.data ?? {}),
          status: "failed",
          errorCode: error?.response.status,
          errorMessage: error?.response.data.message,
        },
      };
    }
  );
};

export const getApi = (requestedApiType) => {
  let apiObj;
  switch (requestedApiType) {
    case apiType.api:
      apiObj = axios.create({
        baseURL: `${config.apiUrl}${config.apiVersion}`,
        ...apiConfig,
      });
      setInterseptors(apiObj);
      setClientLanguage(apiObj);
      setDefaultHeaders(apiObj);
      break;
    case apiType.serverApi:
      apiObj = axios.create({
        baseURL: `//${config.domain}/${config.apiVersion}`,
        ...apiConfig,
      });
      setInterseptors(apiObj);
      setClientLanguage(apiObj);
      setDefaultHeaders(apiObj);
      break;
    case apiType.casino:
      apiObj = axios.create({
        baseURL: `${config.apiUrl}${config.apiVersion}/casino`,
        timeout: apiConfig.timeout,
      });

      apiObj.interceptors.request.use(function (c) {
        if (CASINO_CLOSED) throw new axios.Cancel("Operation canceled.");
        return c;
      });
      break;
  }

  if (apiObj) {
    return apiObj;
  }

  throw new Error(`Api type ${requestedApiType} is not supported`);
};
