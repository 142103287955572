import q from "query-string";

export const isBrowser = () => typeof window !== "undefined";

export const windowUtils = {
  get: (propertyName) => {
    if (!isBrowser()) return;

    return window[propertyName];
  },
};

const localStorageGetItem = (key) => {
  try {
    if (!isBrowser()) return;
    return localStorage.getItem(key);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("localStorage.getItem: ", e);
  }
};

const localStorageSetItem = (key, item) => {
  try {
    if (!isBrowser()) return;
    return localStorage.setItem(key, item);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("localStorage.setItem: ", e);
  }
};

const localStorageRemoveItem = (key) => {
  try {
    if (!isBrowser()) return;
    return localStorage.removeItem(key);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("localStorage.removeItem: ", e);
  }
};

export const localStorageUtils = {
  getItem: localStorageGetItem,
  setItem: localStorageSetItem,
  removeItem: localStorageRemoveItem,
};

const sessionStorageGetItem = (key) => {
  try {
    if (!isBrowser()) return;
    return sessionStorage.getItem(key);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("sessionStorage.getItem: ", e);
  }
};

const sessionStorageSetItem = (key, item) => {
  try {
    if (!isBrowser()) return;
    return sessionStorage.setItem(key, item);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("sessionStorage.setItem: ", e);
  }
};

const sessionStorageRemoveItem = (key) => {
  try {
    if (!isBrowser()) return;
    return sessionStorage.removeItem(key);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("sessionStorage.removeItem: ", e);
  }
};

export const sessionStorageUtils = {
  getItem: sessionStorageGetItem,
  setItem: sessionStorageSetItem,
  removeItem: sessionStorageRemoveItem,
};

export const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const getUtmParams = () => {
  try {
    const utmParams = localStorageUtils.getItem("utmParams");
    if (!utmParams) return null;
    return JSON.parse(utmParams);
  } catch (e) {
    return null;
  }
};

export const setReferral = (referral) => {
  localStorageUtils.setItem("referral", referral);
};

export const getReferral = () => {
  return localStorageUtils.getItem("referral");
};

export function checkReferral() {
  if (!isBrowser()) return;
  const hash = window.location.hash;
  const [_, queryParams] = hash.split("?");
  const urlParams = q.parse(window.location.search || queryParams);
  const ref = urlParams.r;
  const language = urlParams.l;

  const currentUtmParams = getUtmParams();
  const utmParams = {
    source: urlParams.utm_source || "",
    medium: urlParams.utm_medium || "",
    campaign: urlParams.utm_campaign || "",
    term: urlParams.utm_term || "",
    content: urlParams.utm_content || "",
  };
  sessionStorage.setItem("utmParams", JSON.stringify(utmParams));
  if (!currentUtmParams) {
    localStorageUtils.setItem("utmParams", JSON.stringify(utmParams));
  }
  if (language) {
    localStorageUtils.setItem("defaultLanguage", language);
  }

  let hitId = localStorageUtils.getItem("hit");
  if (!hitId && urlParams.clickid) {
    localStorageUtils.setItem("hit", urlParams.clickid);
    hitId = urlParams.clickid;
  }

  let stag = localStorageUtils.getItem("stag");
  if (urlParams.stag) {
    localStorageUtils.setItem("stag", urlParams.stag);
    stag = urlParams.stag;
  }

  let subId = localStorageUtils.getItem("subid");
  if (!subId && urlParams.subid) {
    localStorageUtils.setItem("subid", urlParams.subid);
    subId = urlParams.subid;
  }

  let uid = localStorageUtils.getItem("uid");
  if (!uid && urlParams.uid) {
    localStorageUtils.setItem("uid", urlParams.uid);
    uid = urlParams.uid;
  }

  let sid = localStorageUtils.getItem("sid");
  if (!sid && urlParams.sid) {
    localStorageUtils.setItem("sid", urlParams.sid);
    sid = urlParams.sid;
  }

  if (stag) {
    const stagRef = stag.split("_")[0];
    if (stagRef) {
      setReferral(`sbet${stagRef}`);
    }
  } else if (window.location.host === "betcrypt.io" && !ref) {
    setReferral("gobetcryptio");
  } else if (ref) {
    setReferral(ref);
  }
}

export const isNextPreview = () => {
  return true;
  // return !!localStorageUtils.getItem("nextPreview");
};

export const getSavedCurrency = () => {
  const token = localStorageUtils.getItem("preferred_currency");
  // if (token === "PETH") return "PBTC";
  return token;
};

checkReferral();
