import { BONUS_PACKS_CLEAR, BONUS_PACKS_ERROR, BONUS_PACKS_LOADED, BONUS_PACKS_LOADING } from "actions/actionTypes";

const initialState = {
  bonusPacks: null,
  loading: false,
  loaded: false,
  error: null,
};

const bonusPacksReducer = (state = initialState, action) => {
  switch (action.type) {
    case BONUS_PACKS_LOADING:
      return { ...state, loading: true };
    case BONUS_PACKS_LOADED:
      return {
        ...state,
        loaded: true,
        loading: false,
        bonusPacks: action.payload?.bonusPacks,
      };
    case BONUS_PACKS_CLEAR:
      return {
        ...state,
        loaded: false,
        loading: false,
        bonusPacks: null,
      };
    case BONUS_PACKS_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload?.error,
      };
    default:
      return state;
  }
};

export default bonusPacksReducer;
