export const getExchangeRates = (state) => state.wallet.exchangeRates;
export const getExchangeRatesFiat = (state) => state.wallet.exchangeRates.fiat;
export const getExchangeRatesCrypto = (state) => state.wallet.exchangeRates.crypto;
export const getWalletActiveCurrency = (state) => state.wallet.currency;
export const getWalletActiveTab = (state) => state.wallet.tab;
export const getWalletBalances = (state) => state.wallet.balances;
export const getWalletAddresses = (state) => state.wallet.addresses;
export const getBtcWalletData = (state) =>
  (state.wallet && state.wallet.btcAddress) || null;
export const getBtcWithdrawalData = (state) =>
  (state.wallet && state.wallet.btcWithdrawal) || null;
export const getPTokenWalletData = (state) =>
  (state.wallet && state.wallet.ptokenAddress) || null;
export const getPTokenWithdrawalData = (state) =>
  (state.wallet && state.wallet.ptokenWithdrawal) || null;
