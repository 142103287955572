import React, { useEffect, useRef } from "react";
import { ACTION_TYPE_SLIDER } from "actions/actionTypes";
import { fetchAction } from "actions/fetchActions";
import { fetchSlider } from "api/sliderApi";
import { useInterval } from "hooks/useInterval.ts";
import { Slider } from "components/data/slider";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getSliderItems } from "../../selectors/sliderSelectors";
import { SliderType } from "constants/common.ts";

type Props = {
  type: SliderType;
  slideMaxHeight?: number;
};

const SliderContainer: React.FC<Props> = ({ type, slideMaxHeight }) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const items = useSelector(getSliderItems);

  const loadItems = () => {
    dispatch(
      fetchAction({
        fetchFn: () => fetchSlider(i18n.language, type),
        actionPrefix: ACTION_TYPE_SLIDER,
        withSilent: true,
        advancedMode: true,
        selector: getSliderItems,
      })
    );
  };

  useEffect(() => {
    loadItems();
  }, [i18n.language, type]);

  if (!items?.length) return null;

  return <Slider items={items} slideMaxHeight={slideMaxHeight} />;
};

export default SliderContainer;
