import { eventType } from "constants/events";
import { createSelector } from "reselect";
import { getAccountIsVip } from "selectors/loginSelectors";
import { getPrimaryLeagueOrderThreshold, slugifyBySportId } from "utils/eventUtils";

export const sortByOrderName = (items) => {
  items.sort((a, b) => {
    const diff = (a.order || 10000) - (b.order || 10000);
    return diff !== 0 ? diff : ("" + a.name).localeCompare(b.name);
  });
};

const getStartsEnd = (dateFilter) => {
  const now = Date.now();
  switch (dateFilter || "") {
    case "4H":
      return now + 4 * 3600000;
    case "12H":
      return now + 12 * 3600000;
    case "24H":
      return now + 24 * 3600000;
    default:
      return 0;
  }
};

export const sortByOrderNameAndThreshold = (items, orderThreshold) => {
  //orderThreshold is maximun value for order to count in sorting
  items.sort((a, b) => {
    const aOrder = a.order || 10000;
    const bOrder = b.order || 10000;

    if (a.count === 0 && b.count !== 0) return 1;
    if (a.count !== 0 && b.count === 0) return -1;

    const orderComp = aOrder - bOrder;
    if (
      orderThreshold &&
      orderComp !== 0 &&
      (aOrder < orderThreshold || bOrder < orderThreshold)
    ) {
      return orderComp;
    }

    return ("" + a.name).localeCompare(b.name);
  });
};

const eventPassFilter = (event, filter) =>
  !filter ||
  (event.home && event.home.toLowerCase().indexOf(filter) !== -1) ||
  (event.away && event.away.toLowerCase().indexOf(filter) !== -1);

export const getSports = (sportIds) => (state) => {
  if (!sportIds) return [];
  const sportsById = state.sports.byId;
  return sportIds.map((x) => x && sportsById[x]).filter((x) => x);
};

const getSportsState = (state) => state.sports;
export const getActiveSport = createSelector(
  getSportsState,
  (state) => state.activeSport
);

export const getActiveSportId = createSelector(
  getActiveSport,
  (activeSport) => activeSport.sportId
);

export const getActiveSportLive = createSelector(
  getActiveSport,
  (activeSport) => !!activeSport.live
);

export const getSport = createSelector(
  (state) => state.sports?.byId,
  (_, sportId) => sportId,
  (sportsById, sportId) => sportsById?.[sportId]
);

export const getSportNew = createSelector(
  (state) => state.sports.byId,
  getActiveSportId,
  (state, sportId) => sportId && state[sportId]
);
export const getAllSports = (state) => getSports(state.sports.allIds)(state);
export const getPreSports = (state) => getSports(state.sports.preIds)(state);
export const getLiveSports = (state) => getSports(state.sports.liveIds)(state);
export const getSportFilter = (state) => state.sports.filter;
export const getSportDateFilter = (state) => state.sports.dateFilter;
export const getSportsById = (state) => state.sports.byId;

const getEvents = (state) => state.events;
export const getEventsById = createSelector(getEvents, (state) => state.byId);

const getAvailableSportEvents = createSelector(
  getAccountIsVip,
  getEventsById,
  getActiveSportId,
  getActiveSportLive,
  (isVip, eventsById, sportId, live) => {
    if (!sportId) return [];
    const targetType = live ? eventType.live : eventType.prematch;
    let result = Object.getOwnPropertyNames(eventsById)
      .map((x) => eventsById[x])
      .filter((x) => x.sport === sportId && x.type === targetType && x.bet);
    if (!isVip && targetType === eventType.prematch) {
      for (const event of result) {
        if (!event.odds) continue;
        event.odds = { ...event.odds };
        let bet = false;
        for (const market of Object.keys(event.odds)) {
          if (market === "bet") continue;
          const odd = event.odds[market];
          if (Array.isArray(odd)) {
            const oddPrepared = odd.filter((x) => typeof x.vip === "undefined" || !x.vip);
            if (!oddPrepared.length) delete event.odds[market];
            if (oddPrepared.some((x) => !!x.bet)) bet = true;
            event.odds[market] = oddPrepared;
          } else if (odd.vip) {
            delete event.odds[market];
          } else if (odd.bet) {
            bet = true;
          }
        }

        if (!bet) {
          event.bet = false;
        }
      }
    }
    if (targetType === eventType.prematch) {
      result = result.filter(
        (x) =>
          !!x.bet &&
          !!x.odds &&
          (!!x.odds[1] || !!x.odds[9] || !!x.odds[46] || !!x.odds[3] || !!x.odds[10])
      );
    }
    return result;
  }
);

const getSportEvents = createSelector(getAvailableSportEvents, (events) => {
  const result = [...events];
  result.sort((a, b) => new Date(a.starts).getTime() - new Date(b.starts).getTime());
  return result;
});

export const getLeagues = (state) => state.leagues;
export const getLeaguesById = createSelector(getLeagues, (state) =>
  Object.values(state.byId)
);
export const getLeaguesBySport = createSelector(
  getAccountIsVip,
  getActiveSportId,
  getLeaguesById,
  (isVip, sportId, state) => {
    const filterFn = isVip
      ? (x) => x.sport === sportId
      : (x) => x.sport === sportId && !x.vipOnly;
    const result = state.filter(filterFn);
    sortByOrderName(result);
    return result;
  }
);

const getSportsFilter = createSelector(getSportsState, (state) => state.filter || "");
const getSportsDateFilter = createSelector(getSportsState, (state) => state.dateFilter);
export const getLiveStreamFilter = createSelector(
  getSportsState,
  (state) => !!state.liveStreamsFilter
);

export const getEventsCountByHours = createSelector(
  getAvailableSportEvents,
  getLeagues,
  getAccountIsVip,
  (events, leagues, isVip) => {
    const result = {
      "4H": 0,
      "12H": 0,
      "24H": 0,
      ALL: 0,
    };
    const ranges = {
      4: Date.now() + 4 * 60 * 60 * 1000,
      12: Date.now() + 12 * 60 * 60 * 1000,
      24: Date.now() + 24 * 60 * 60 * 1000,
    };

    for (const event of events) {
      const eventLeague = leagues.byId[event.league];
      if (!eventLeague || eventLeague.exclude) continue;
      if (!isVip && eventLeague.vipOnly) continue;
      const starts = new Date(event.starts).getTime();
      if (starts < Date.now()) continue;
      if (starts <= ranges[4]) {
        result["4H"] += 1;
        result["12H"] += 1;
        result["24H"] += 1;
      } else if (starts <= ranges[12]) {
        result["12H"] += 1;
        result["24H"] += 1;
      } else if (starts <= ranges[24]) {
        result["24H"] += 1;
      }
      result.ALL += 1;
    }

    return result;
  }
);

export const getEventsCountByLive = createSelector(
  getAvailableSportEvents,
  getLeagues,
  getAccountIsVip,
  getSportsFilter,
  (events, leagues, isVip, filter) => {
    const result = {
      Yes: 0,
      All: 0,
    };

    for (const event of events) {
      const eventLeague = leagues.byId[event.league];
      if (!eventLeague || eventLeague.exclude) continue;
      if (!isVip && eventLeague.vipOnly) continue;
      if (!eventPassFilter(event, filter)) continue;

      if (event.liveStream) {
        result["Yes"] += 1;
      }
      result.All += 1;
    }

    return result;
  }
);

export const getSportView = createSelector(
  getLiveStreamFilter,
  getLeaguesBySport,
  getSportEvents,
  getSportNew,
  getSportsFilter,
  getSportsDateFilter,
  getActiveSportId,
  getActiveSportLive,
  (liveStreamsFilter, leagues, events, sport, filterValue, dateFilter, sportId, live) => {
    if (!sport) return null;
    if (live && liveStreamsFilter) {
      events = events.filter((x) => !!x.liveStream);
    }
    const eventsByLeague = events.reduce((p, x) => {
      if (p[x.league]) {
        p[x.league].push(x);
      } else {
        p[x.league] = [x];
      }
      return p;
    }, {});
    const leaguesByGroup = leagues.reduce((p, x) => {
      if (p[x.group]) {
        p[x.group].push(x);
      } else {
        p[x.group] = [x];
      }
      return p;
    }, {});

    const filter = filterValue.toLowerCase();
    const startsEnd = !live && getStartsEnd(dateFilter);

    const groups = Object.entries(leaguesByGroup)
      .map(([group, groupLeagues]) => {
        const leagues = groupLeagues
          .map((league) => {
            let events = eventsByLeague[league._id];

            if (events) {
              if (filter) {
                if (league.name.toLowerCase().indexOf(filter) === -1) {
                  events = events && events.filter((x) => eventPassFilter(x, filter));
                }
              }
              if (startsEnd) {
                events =
                  events &&
                  events.filter((x) => x.starts && x.starts.getTime() <= startsEnd);
              }
            } else if (!live && sport && !!league?.isSeoLeague) {
              events = [];
            }
            return {
              league,
              events,
            };
          })
          .filter((c) => (startsEnd || filter ? c.events?.length : c.events)); 

        const count = leagues.reduce((p, x) => p + x.events.length, 0);
        leagues.sort((a, b) => {
          if (a.events.length === 0 && b.events.length !== 0) return 1;
          if (a.events.length !== 0 && b.events.length === 0) return -1;
          return 0;
        });

        return {
          name: group,
          slug: slugifyBySportId(group, sport._id),
          order: leagues.length ? leagues[0].league.order : undefined,
          leagues,
          count,
        };
      })
      .filter((x) => x.count || x.leagues.length);

    sortByOrderNameAndThreshold(groups, getPrimaryLeagueOrderThreshold(live));

    return {
      sport,
      groups,
    };
  }
);
export const getLeaguesCountries = createSelector(getLeaguesBySport, (state) => {
  const result = new Set();
  for (const league of state) {
    result.add(league.group);
  }
  return Array.from(result);
});

export const getLiveSportsCount = (state) => {
  const isVip = getAccountIsVip(state);
  const liveSports = state.sports.liveSports;
  const result = {};
  for (let [sportId, sources] of Object.entries(liveSports)) {
    if (!result[sportId]) result[sportId] = 0;
    Object.values(sources).forEach((x) => {
      result[sportId] += isVip ? x.all : x.all - x.vipOnly;
    });
  }
  return result;
};
