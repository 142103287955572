import {
  RISK_FREE_CLEAR,
  RISK_FREE_LOADED,
  RISK_FREE_UNSET_MODAL,
} from "actions/actionTypes";
import { clearUserRiskFreeModal, fetchUserRiskFree } from "../api/bonusApi";

export const closePromoModal = () => async (dispatch) => {
  dispatch({ type: RISK_FREE_UNSET_MODAL });
  await clearUserRiskFreeModal();
};
export const getUserRiskFreeBonus = () => async (dispatch) => {
  try {
    const participation = await fetchUserRiskFree();
    if (!participation) {
      dispatch({ type: RISK_FREE_CLEAR });
      return;
    }

    dispatch({
      type: RISK_FREE_LOADED,
      payload: participation,
    });
  } catch (e) {
    dispatch({ type: RISK_FREE_CLEAR });
  }
};
