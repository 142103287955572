import {
  UNSUBSCRIBE_CLOSE,
  UNSUBSCRIBE_ERROR,
  UNSUBSCRIBE_INIT,
  UNSUBSCRIBE_LOADING,
  UNSUBSCRIBE_SUCCESS,
} from "actions/actionTypes";
import { getError } from "api/api";
import { unsubscribeUser } from "api/userApi";
import { getUnsubscribeCode, getUnsubscribeEmail } from "selectors/unsubscribeSelectors";

export const unsubscribeInit = (email, code) => ({
  type: UNSUBSCRIBE_INIT,
  payload: { email, code },
});

export const unsubscribe = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: UNSUBSCRIBE_LOADING,
    });
    const state = getState();
    const code = getUnsubscribeCode(state);
    const email = getUnsubscribeEmail(state);
    await unsubscribeUser(email, code);
    dispatch({
      type: UNSUBSCRIBE_SUCCESS,
    });
  } catch (e) {
    const error = getError(e);
    dispatch({
      type: UNSUBSCRIBE_ERROR,
      payload: { error },
    });
  }
};

export const unsubscribeClose = () => ({
  type: UNSUBSCRIBE_CLOSE,
});
