import {
  USER_SETTINGS_CLEAR,
  USER_SETTINGS_ERROR,
  USER_SETTINGS_LOADED,
  USER_SETTINGS_LOADING,
  USER_SETTINGS_SAVED,
  USER_SETTINGS_SAVING,
} from "actions/actionTypes";
import produce from "immer";

const initialState = {
  loading: false,
  loaded: false,
  error: null,
  saving: false,
  saved: false,
  data: null,
};

const userSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_SETTINGS_LOADING:
      return produce(state, (draft) => {
        draft.loading = true;
        draft.error = null;
        draft.loaded = false;
        draft.saving = false;
        draft.saved = false;
      });
    case USER_SETTINGS_LOADED:
      return produce(state, (draft) => {
        draft.error = null;
        draft.loading = false;
        draft.loaded = true;
        draft.data = action.payload.settings;
      });
    case USER_SETTINGS_ERROR:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.error = action.payload.error;
      });
    case USER_SETTINGS_SAVING:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.error = null;
        draft.saving = true;
      });
    case USER_SETTINGS_SAVED:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.error = null;
        draft.saved = true;
        draft.data = action.payload.settings;
      });
    case USER_SETTINGS_CLEAR:
      return initialState;
    default:
      return state;
  }
};

export default userSettingsReducer;
