import { FC } from "react";
import { ReactComponent as BitcoinIcon } from "img/token-icons/bitcoin-icon.svg";
import { ReactComponent as EOSIcon } from "img/token-icons/eos-icon.svg";
import { ReactComponent as EthereumIcon } from "img/token-icons/ethereum-icon.svg";
import { ReactComponent as LitecoinIcon } from "img/token-icons/litecoin-icon.svg";
import { ReactComponent as SbetIcon } from "img/token-icons/sbet-icon.svg";
import { ReactComponent as TetherIcon } from "img/token-icons/tether-icon.svg";

export enum TokenNames {
  ETHEREUM = "ethereum",
  LITECOIN = "litecoin",
  BITCOIN = "bitcoin",
  TETHER = "tether",
  SBET = "sbet",
  EOS = "eos",
}

export const TokenIcon: FC<{ iconName: TokenNames }> = ({ iconName }) => {
  const getIcon = (name: TokenNames) => {
    switch (name) {
      case TokenNames.ETHEREUM:
        return <EthereumIcon />;
      case TokenNames.LITECOIN:
        return <LitecoinIcon />;
      case TokenNames.BITCOIN:
        return <BitcoinIcon />;
      case TokenNames.TETHER:
        return <TetherIcon />;
      case TokenNames.SBET:
        return <SbetIcon />;
      case TokenNames.EOS:
        return <EOSIcon />;
    }
  };
  return <div className="icon">{getIcon(iconName)}</div>;
};
