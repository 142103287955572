export const CASINO_GAMELIST_PAGE_SET = "CASINO_GAMELIST_PAGE_SET";
export const CASINO_GAMELIST_LOADING = "CASINO_GAMELIST_LOADING";
export const CASINO_GAMELIST_CHANGE_PAGE = "CASINO_GAMELIST_CHANGE_PAGE";
export const CASINO_GAMELIST_CHANGE_ACTIVE_PAGES = "CASINO_GAMELIST_CHANGE_ACTIVE_PAGES";
export const CASINO_GAMELIST_LOAD_ERROR = "CASINO_GAMELIST_LOAD_ERROR";
export const CASINO_GAMELIST_PROVIDERS_SET = "CASINO_GAMELIST_PROVIDERS_SET";
export const CASINO_GAMELIST_SEARCH_SET = "CASINO_GAMELIST_SEARCH_SET";
export const CASINO_GAMELIST_SORT_BY_SET = "CASINO_GAMELIST_SORT_BY_SET";
export const CASINO_GAMELIST_CATEGORY_SET = "CASINO_GAMELIST_CATEGORY_SET";
export const CASINO_GAMELIST_PARAMS_INIT = "CASINO_GAMELIST_PARAMS_INIT";
export const CASINO_GAMELIST_IS_RELOAD_REQUIRED = "CASINO_GAMELIST_IS_RELOAD_REQUIRED";

export const CASINO_HISTORY_LOADING = "CASINO_HISTORY_LOADING";
export const CASINO_HISTORY_LOADED = "CASINO_HISTORY_LOADED";
export const CASINO_HISTORY_ERROR = "CASINO_HISTORY_ERROR";
export const CASINO_HISTORY_CLEAR = "CASINO_HISTORY_CLEAR";

export const CASINO_GAMELOBBY_LOADING = "CASINO_GAMELOBBY_LOADING";
export const CASINO_GAMELOBBY_LOADED = "CASINO_GAMELOBBY_LOADED";
export const CASINO_GAMELOBBY_LOAD_ERROR = "CASINO_GAMELOBBY_LOAD_ERROR";

export const CASINO_GAME_LOADING = "CASINO_GAME_LOADING";
export const CASINO_GAME_LOADED = "CASINO_GAME_LOADED";
export const CASINO_GAME_ERROR = "CASINO_GAME_ERROR";
export const CASINO_GAME_RELATED_LOADED = "CASINO_GAME_RELATED_LOADED";
export const CASINO_GAME_DEPOSIT_SHOWN_SET = "CASINO_GAME_DEPOSIT_SHOWN_SET";
export const CASINO_GAME_REFILL_SHOWN_SET = "CASINO_GAME_REFILL_SHOWN_SET";
export const CASINO_GAME_REFILL_PROGRESS = "CASINO_GAME_REFILL_PROGRESS";
export const CASINO_GAME_REFILLED = "CASINO_GAME_REFILLED";
export const CASINO_GAME_REFILL_ERROR = "CASINO_GAME_REFILL_ERROR";
export const CASINO_GAME_DEMO_STARTING = "CASINO_GAME_DEMO_STARTING";
export const CASINO_GAME_DEMO_STARTED = "CASINO_GAME_DEMO_STARTED";
export const CASINO_GAME_STARTING = "CASINO_GAME_STARTING";
export const CASINO_GAME_STARTED = "CASINO_GAME_STARTED";
export const CASINO_GAME_DEMO_ERROR = "CASINO_GAME_DEMO_ERROR";
export const CASINO_GAME_END = "CASINO_GAME_END";
export const CASINO_GAME_DEMO_END = "CASINO_GAME_DEMO_END";
export const CASINO_GAME_SET_ID = "CASINO_GAME_SET_ID";
