import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getExperiments } from "selectors/experimentsSelector";
import { localStorageUtils } from "utils/browserUtils";

const EXPERIMENTS_TRACKING = "abTracking";

type Experiment = {
  eid: string;
  vid: string;
};

export const useSetupExperiments = () => {
  const experiments = useSelector(getExperiments);

  useEffect(() => {
    try {
      const tracking = JSON.parse(
        localStorageUtils.getItem(EXPERIMENTS_TRACKING) ?? "{}"
      );

      if (!Array.isArray(tracking))
        localStorageUtils.setItem(EXPERIMENTS_TRACKING, JSON.stringify([]));

      for (const experiment of experiments) {
        if (
          tracking.some(
            (o: Experiment) => o.eid === experiment.eid && o.vid === experiment.vid
          )
        )
          continue;

        window.dataLayer.push({
          event: "experiment_assign",
          eid: experiment.eid,
          vid: experiment.vid,
        });

        tracking.push(experiment);
        localStorageUtils.setItem(EXPERIMENTS_TRACKING, JSON.stringify(tracking));
      }
    } catch (e) {
      console.error(e);
    }
  }, [experiments]);
};
