import { EVENTS_LOADED, HOMEPAGE_SPORT_LOADED, SPORT_LOADED } from "actions/actionTypes";
import produce from "immer";
import { combineReducers } from "redux";
import { normalizeLeague } from "utils/projectUtils";

function byIdReducer(state = {}, action) {
  switch (action.type) {
    case HOMEPAGE_SPORT_LOADED:
      const { prematchLeagues, liveLeagues } = action.payload;
      return produce(state, (draft) => {
        prematchLeagues.forEach((x) => {
          const league = normalizeLeague(x);
          draft[league._id] = league;
        });
        liveLeagues.forEach((x) => {
          const league = normalizeLeague(x);
          draft[league._id] = league;
        });
      });
    case SPORT_LOADED:
    case EVENTS_LOADED: {
      const { leagues } = action.payload;
      const newState = { ...state };
      Array.isArray(leagues) &&
        leagues.forEach((x) => {
          newState[x._id] = x;
        });
      return newState;
    }
    default:
      return state;
  }
}

const leaguesReducer = combineReducers({
  byId: byIdReducer,
});

export default leaguesReducer;
