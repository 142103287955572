import React, { useState } from "react";
import bonusImg from "../../images/bonus.svg";
import HashLink from "components/common/HashLink";
import { localStorageUtils } from "../../utils/browserUtils";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DepositBonusStrip = ({ bonus }) => {
  const [hidden, setHidden] = useState(false);
  const isBonusInfoHidden = localStorageUtils.getItem("hideBonusInfo");

  const hideBonusInfo = () => {
    localStorageUtils.setItem("hideBonusInfo", true);
    setHidden(true);
  };

  if (hidden || isBonusInfoHidden) return null;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#323F5B",
        height: 32,
        textAlign: "center",
        overflow: "hidden",
        whiteSpace: "nowrap",
        lineHeight: "32px",
        width: "100%",
        fontSize: "14px",
        color: "#fff",
      }}
    >
      <img alt="bonus" style={{ width: 14, height: 14, marginRight: 6 }} src={bonusImg} />
      <div
        style={{
          alignItems: "center",
        }}
        className={"d-flex"}
      >
        <span className={"d-none d-sm-none d-md-inline-block d-lg-inline-block"}>
          {bonus.name} has been credited!
        </span>
        <HashLink style={{ color: "#73e698", textDecoration: "underline" }} to={"wallet"}>
          Activate
        </HashLink>
      </div>

      <FontAwesomeIcon
        icon={faTimes}
        style={{
          position: "absolute",
          right: "10px",
          cursor: "pointer",
        }}
        onClick={(e) => {
          e.stopPropagation();
          hideBonusInfo();
        }}
      />
    </div>
  );
};

export default DepositBonusStrip;
