import { faCircleNotch } from "@fortawesome/free-solid-svg-icons/faCircleNotch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { logout } from "actions/loginActions.ts";
import {
  changeTfaConfirmCode,
  toggleTfaConfirmRecovery,
  unlockTfaConfirm,
} from "actions/tfaConfirmActions";
import Button from "components/common/Button";
import Input from "components/common/Input";
import Popup from "components/common/Popup.tsx";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getUserTfaConfirm } from "selectors/userSelectors";
import { loadUserStats } from "../../../actions/userStatsActions";

const TfaConfirm = () => {
  const { t } = useTranslation();
  const [codeError, setCodeError] = useState(null);
  const history = useHistory();
  const { locked, code, recovery, loading, error } = useSelector(getUserTfaConfirm);
  const dispatch = useDispatch();

  const toggleForm = useCallback(
    (e) => {
      e.preventDefault();
      setCodeError(null);
      dispatch(toggleTfaConfirmRecovery());
    },
    [dispatch, setCodeError]
  );

  const changeCode = useCallback(
    (value) => {
      setCodeError(null);
      dispatch(changeTfaConfirmCode(value));
    },
    [dispatch]
  );

  const confirmTfa = useCallback(
    (e) => {
      e.preventDefault();
      if (!code) {
        setCodeError("Code is required");
        return;
      }
      if (!recovery && !/\d{6}/.test(code)) {
        setCodeError("Wrong TFA code format");
        return;
      }
      setCodeError(null);
      dispatch(unlockTfaConfirm(history));
      dispatch(loadUserStats());
    },
    [code, dispatch]
  );

  if (!locked) return null;

  return (
    <Popup
      title={t("Two-Factor Authentication")}
      onClose={() => {
        dispatch(logout());
      }}
    >
      <div className={"tfa-settings__form"} style={{ maxWidth: 300, margin: "0 auto" }}>
        <form onSubmit={confirmTfa}>
          <Input
            type={recovery ? "text" : "number"}
            value={code}
            placeholder={recovery ? t("Type recovery code") : t("Type 2FA code")}
            onChange={changeCode}
            error={codeError ? t(codeError) : error ? t("Wrong TFA code") : undefined}
            autoFocus
          />
          {/* error ? <FormErrorMessage text={t("Wrong TFA code")} style={{ margin: "0 0 12px" }} /> : null */}
          <div className={"settings__form-footer"}>
            <Button type={"submit"} disabled={loading} secondary className={"btn-main"}>
              {loading ? (
                <FontAwesomeIcon icon={faCircleNotch} spin />
              ) : recovery ? (
                t("Deactivate TFA")
              ) : (
                t("Confirm TFA code")
              )}
            </Button>
          </div>
          <a href={"#"} className={"settings__tfa-recovery-link"} onClick={toggleForm}>
            {!recovery ? t("Use recovery code") : t("Use 2FA code")}
          </a>
        </form>
      </div>
    </Popup>
  );
};

export default TfaConfirm;
