import {
  HOMEPAGE_CHANGE_SPORT,
  HOMEPAGE_RESET_SPORT,
  HOMEPAGE_SPORT_ERROR,
  HOMEPAGE_SPORT_LOAD,
  HOMEPAGE_SPORT_LOADED,
} from "actions/actionTypes";
import { sportsNavItems } from "constants/sports";
import produce from "immer";
import { combineReducers } from "redux";

const defaultSport = sportsNavItems[0]._id;

const sport = (state = defaultSport, action) => {
  switch (action.type) {
    case HOMEPAGE_CHANGE_SPORT:
      return action.payload?.sport;
    case HOMEPAGE_RESET_SPORT:
      return defaultSport;
    default:
      return state;
  }
};

const sportsInitialState = sportsNavItems
  .map(({ _id: id }) => ({
    sport: id,
    loading: false,
    updating: false,
    error: null,
    loaded: false,
    last: null,
    preEventIds: [],
    liveEventIds: [],
  }))
  .reduce((res, x) => {
    res[x.sport] = x;
    delete x.sport;
    return res;
  }, {});

const sports = (state = sportsInitialState, action) => {
  switch (action.type) {
    case HOMEPAGE_SPORT_LOAD:
      return produce(state, (draft) => {
        draft[action.payload?.sport].loading = true;
        draft[action.payload?.sport].error = null;
      });
    case HOMEPAGE_SPORT_ERROR:
      return produce(state, (draft) => {
        draft[action.payload?.sport].loading = false;
        draft[action.payload?.sport].error = action.payload?.error;
      });
    case HOMEPAGE_SPORT_LOADED: {
      const preEventIds = (action.payload?.prematchEvents || []).map((x) => x._);
      const liveEventIds = (action.payload?.liveEvents || []).map((x) => x._);

      return produce(state, (draft) => {
        draft[action.payload?.sport].loading = false;
        draft[action.payload?.sport].error = null;
        draft[action.payload?.sport].loaded = true;
        draft[action.payload?.sport].preEventIds = preEventIds;
        draft[action.payload?.sport].liveEventIds = liveEventIds;
      });
    }
    default:
      return state;
  }
};

const monitoring = (state = false, action) => {
  switch (action.type) {
    case HOMEPAGE_SPORT_LOAD:
      return true;
    case HOMEPAGE_RESET_SPORT:
      return false;
    default:
      return state;
  }
};

export default combineReducers({ sport, sports, monitoring });
