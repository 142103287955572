import { BONUS_CANCEL_ERROR, BONUS_CANCEL_LOADED, BONUS_CANCEL_LOADING } from "actions/actionTypes";

const initialState = {
  loading: false,
  loaded: false,
  error: null,
};

const bonusCancelReducer = (state = initialState, action) => {
  switch (action.type) {
    case BONUS_CANCEL_LOADING:
      return { ...state, loading: true };
    case BONUS_CANCEL_LOADED:
      return { ...state, loaded: true, loading: false };
    case BONUS_CANCEL_ERROR:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload?.error,
      };
    default:
      return state;
  }
};

export default bonusCancelReducer;
