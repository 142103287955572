import { faAngleUp } from "@fortawesome/free-solid-svg-icons/faAngleUp";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import cx from "classnames";
import { NavLink } from "react-router-dom";
import {
  InputGroup,
  InputGroupButtonDropdown,
  InputGroupAddon,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { useHistory, useLocation } from "react-router";
import { getIsScatterUser, getEosAccount } from "selectors/loginSelectors";
import { getAccountCurrencyMetadata } from "selectors/loginSelectors";
import { getWalletBalances } from "selectors/walletSelectors";
import {
  getIsHideZero,
  getIsShowBonus,
  getViewFiat,
  getIsFiatCurrency,
  getSettingsFiatCurrency,
} from "selectors/uiSelectors";
import {
  bonusCurrenciesOrder,
  currenciesOrderScatter,
  currenciesOrderSocial,
} from "constants/currencies";
import { changeCurrency } from "actions/currencyActions";
import { walletLoadBalances } from "actions/walletActions";
import { setFiatView, setHideZero, setShowBonus } from "actions/uiActions";
import { currencies } from "constants/currencies";
import Sum, { BalanceSum } from "components/common/Sum";
import { isMBtc } from "utils/currencyUtils";
import { localStorageUtils } from "utils/browserUtils";
import { ReactComponent as WalletIcon } from "img/header-icons/wallet-icon.svg";

const UserBalance = ({ className }) => {
  const { t } = useTranslation();
  const [isDropdownOpen, setisDropdownOpen] = useState(false);
  const currencyMetadata = useSelector(getAccountCurrencyMetadata);
  const eosAccount = useSelector(getEosAccount);
  const balances = useSelector(getWalletBalances);
  const balance =
    !balances || !balances.data
      ? "Loading..."
      : balances.data[currencyMetadata.name] * (isMBtc(currencyMetadata.name) ? 1000 : 1);
  const dispatch = useDispatch();
  const isScatterUser = useSelector(getIsScatterUser);
  const isHideZero = useSelector(getIsHideZero);
  const isFiat = useSelector(getIsFiatCurrency);
  const isShowBonus = useSelector(getIsShowBonus);
  const viewFiat = useSelector(getViewFiat);
  const fiatCurrency = useSelector(getSettingsFiatCurrency);
  const history = useHistory();
  const { pathname } = useLocation();
  useEffect(() => {
    if (!eosAccount) return;
    dispatch(walletLoadBalances(eosAccount));
  }, [eosAccount]);
  const currencyName = currencyMetadata.name;
  const hasError = currencyMetadata.hasError;
  const balanceValue = balance;
  const toggleFiat = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      dispatch(setFiatView(!viewFiat));
      if (!isFiat && !viewFiat && !fiatCurrency) {
        history.replace({ hash: "#currency" });
      }
    },
    [dispatch, isFiat, pathname, viewFiat, fiatCurrency]
  );
  const toggleHideZero = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      dispatch(setHideZero(!isHideZero));
    },
    [dispatch, isHideZero]
  );
  const setShowBonusValue = useCallback(
    async (showBonus) => {
      dispatch(setShowBonus(showBonus));
    },
    [dispatch]
  );
  const showCurrencyToggle =
    bonusCurrenciesOrder.some(
      (currCode) => !!balances && balances.data && balances.data[currCode]
    ) || isShowBonus;
  const currenciesOrder = useMemo(() => {
    if (showCurrencyToggle && isShowBonus) {
      return bonusCurrenciesOrder;
    }
    return isScatterUser ? currenciesOrderScatter : currenciesOrderSocial;
  }, [isScatterUser, showCurrencyToggle, isShowBonus]);

  useEffect(() => {
    if (balances && !balances.loading && balances.data && balances.data.BTC > 0) {
      if (!localStorageUtils.getItem("btcConvertOnce")) {
        localStorageUtils.setItem("btcConvertOnce", 1);
        history.push("#wallet");
      }
    }
  }, [balances && balances.loading, balances && balances.data && balances.data.BTC]);

  if (hasError) {
    return <div />;
  }

  return (
    <InputGroup
      className={cx(`user-balance-container`, className, "user-balance-conversion")}
      size="sm"
    >
      <InputGroupButtonDropdown
        addonType="prepend"
        isOpen={isDropdownOpen}
        toggle={() => setisDropdownOpen(!isDropdownOpen)}
      >
        <DropdownToggle>
          <BalanceSum
            value={isMBtc(currencyName) ? balanceValue / 1000 : balanceValue}
            token={currencyName}
            tokenCaption={false}
            fiatCaption={true}
          />
          <FontAwesomeIcon
            className="button-icon-end"
            icon={isDropdownOpen ? faAngleUp : faAngleDown}
          />
        </DropdownToggle>
        <DropdownMenu>
          {showCurrencyToggle && (
            <div
              className="btn-group btn-group-toggle currency-type-toggle"
              data-toggle="buttons"
            >
              <label
                className={`btn btn-secondary ${isShowBonus ? "" : "selected"}`}
                onClick={() => setShowBonusValue(false)}
              >
                <input type="radio" name="options" id="real" autoComplete="off" />
                {t("Real")}
              </label>
              <label
                className={`btn btn-secondary ${isShowBonus ? "selected" : ""}`}
                onClick={() => setShowBonusValue(true)}
              >
                <input type="radio" name="options" id="bonus" autoComplete="off" />
                {t("Bonus")}
              </label>
            </div>
          )}

          {currenciesOrder.map((currencyCode) => {
            const curr = currencies[currencyCode];
            const value =
              balances && balances.data && balances.data[curr.name]
                ? balances.data[curr.name]
                : 0;

            // hide empty values
            if ((isHideZero || isShowBonus) && !value) {
              return null;
            }

            return (
              <DropdownItem
                onClick={() => dispatch(changeCurrency(curr.name))}
                key={curr.name}
                className={cx({ active: currencyName === curr.name })}
              >
                <Sum
                  value={value}
                  token={curr.realName || curr.name}
                  tokenCaption={true}
                  balanceView={true}
                />
              </DropdownItem>
            );
          })}
          <div className="separator" />
          <div className="balance-picker-filters">
            <div className="balance-picker-filter" onClick={toggleFiat}>
              <label className="switch" htmlFor={"toggle-fiat-checkbox"}>
                <input
                  type="checkbox"
                  id={"toggle-fiat-checkbox"}
                  checked={!!viewFiat}
                  onChange={() => {}}
                />
                <span className="slider" />
              </label>
              <span className="balance-picker-filter-name">{t("View in $/€/₺")}</span>
            </div>
            <div className="balance-picker-filter" onClick={toggleHideZero}>
              <label className="switch" htmlFor={"hide-zeros-checkbox"}>
                <input
                  type="checkbox"
                  id="hide-zeros-checkbox"
                  checked={!!isHideZero}
                  onChange={() => {}}
                />
                <span className="slider" />
              </label>
              <span className="balance-picker-filter-name">
                {t("Hide zero balances")}
              </span>
            </div>
          </div>
        </DropdownMenu>
      </InputGroupButtonDropdown>
      <InputGroupAddon addonType="append" className="btn-separated-addon">
        <NavLink
          aria-label="Open wallet"
          to={pathname + "#wallet"}
          className="btn btn-sm btn-secondary"
        >
          <WalletIcon />
        </NavLink>
      </InputGroupAddon>
    </InputGroup>
  );
};

export default UserBalance;
