import cx from "classnames";
import React from "react";
import { Input as InputBase } from "reactstrap";

const Input = ({
  type = "text",
  placeholder,
  value,
  error,
  caption,
  onChange,
  className,
  disabled,
  onBlur,
  icon,
  ...props
}) => {
  return (
    <div className={"form-group form-element"}>
      <div className={cx("input", className, { "input-icon": !!icon })}>
        {icon ? <img src={icon} /> : null}
        <InputBase
          type={type}
          className={cx({ invalid: !!error })}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          onBlur={onBlur}
          {...props}
        />
      </div>
      {error || caption ? (
        <div className={"input-additions"}>
          {error ? <div className="invalid-feedback">{error}</div> : null}
          {caption ? <div className="caption">{caption}</div> : null}
        </div>
      ) : null}
    </div>
  );
};

export default Input;
