import { store } from "config/store";
import { apiType, getApi } from "./apiFactory";
import { SET_ODDS_FORMAT } from "actions/actionTypes";
import { oddsFormat } from "constants/odds";
import { localStorageUtils } from "utils/browserUtils";

export const fetchPage = async (language, type, slug) => {
  try {
    const { data, headers } = await getApi(apiType.serverApi).post(`/page/page2`, {
      language,
      type,
      slug,
    });

    const localStorageOddsFormat = Number.parseInt(
      localStorageUtils.getItem("odds_format"),
      10
    );

    const country = headers["s-ip-country"] || "";
    const initialOddsFormat =
      country.toLowerCase() === "us" ? oddsFormat.american : oddsFormat.decimal;

    store.dispatch({
      type: SET_ODDS_FORMAT,
      payload: isNaN(localStorageOddsFormat) ? initialOddsFormat : localStorageOddsFormat,
    });
    return data;
  } catch (e) {
    console.error(`Failed to fetch page ${language} ${type} ${slug}`, e);
    return null;
  }
};
