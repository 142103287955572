import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useSelector } from "react-redux";
import { getAccountIsVip } from "selectors/loginSelectors";
import { toHHMM } from "utils/dateUtils";
import { marketType } from "@sbetdev2/dbmodel/enums";
import { sports } from "constants/sports";
import { eventType, betSide } from "constants/events";
import {
  getEventOddCount,
  getBet,
  formatHdp,
  getOddsBalancedValue,
  getSportMainMarket,
  getSportHandicapMarket,
  getSportTotalMarket,
} from "utils/eventUtils";
import BetButtonBase from "components/bet/BetButton.tsx";
import NavLink from "components/common/NavLink.tsx";
import { Badge } from "reactstrap";
import { getEventUrl } from "utils/urlUtils";
import { ReactComponent as PlayIcon } from "img/play-icon.svg";

const BetButton = (props) => {
  return <BetButtonBase {...props} short />;
};

const extractMinutesFromStatus = (text) => {
  const match = (text || "").match(/(\d+)\smin/);
  return match && match[1] ? match[1] + "′" : "";
};

const EventShort = ({ event, t }) => {
  const { _id, starts, home, away, type, statusText, results } = event;
  const liveEventTime = extractMinutesFromStatus(statusText);
  const live = type === eventType.live;
  const isVip = useSelector(getAccountIsVip);

  let mlMarket = getSportMainMarket(event.sport);
  if (event.odds && !event.odds[mlMarket]) {
    if ([sports.hockey, sports.rugbyleague, sports.rugbyunion].includes(event.sport)) {
      if (event.odds[marketType.winner2MatchAll]) {
        mlMarket = marketType.winner2MatchAll;
      } else {
        mlMarket = marketType.winner3Match;
      }
    } else if ([sports.esports, sports.cricket].includes(event.sport)) {
      mlMarket = marketType.winner2Match;
    }
  }

  const winner3Match = mlMarket === marketType.winner3Match;

  const hdpMarket = getSportHandicapMarket(event.sport);
  const hdp = event.odds && event.odds[hdpMarket];
  const hdpValue = getOddsBalancedValue(hdp, (x) => x.home - x.away);
  const hdpHome = getBet(
    event,
    {
      market: hdpMarket,
      side: betSide.home,
      value: hdpValue,
    },
    isVip
  );
  const hdpAway = getBet(
    event,
    {
      market: hdpMarket,
      side: betSide.away,
      value: hdpValue,
    },
    isVip
  );

  const totalMarket = getSportTotalMarket(event.sport);
  const total = event.odds && event.odds[totalMarket];
  const totalValue = getOddsBalancedValue(total, (x) => x.over - x.under);

  const winnerHome = getBet(
    event,
    {
      market: mlMarket,
      side: betSide.home,
    },
    isVip
  );
  const winnerAway = getBet(
    event,
    {
      market: mlMarket,
      side: betSide.away,
    },
    isVip
  );

  const oddCount = getEventOddCount(event);
  const isModerator = false; // todo: useSelector(getIsModer);
  const isNoDrawLine = !winner3Match && !((live || isModerator) && event.liveStream);
  const isAnyMarket = event.bet && event.odds && event.odds.bet;
  const showScore = live && results && results.ft;

  return (
    <NavLink
      to={getEventUrl(event)}
      relatedIds={{
        sportId: event.sport,
        leagueId: event.league,
        eventId: event._id,
      }}
      className="event-short"
    >
      <div className="event-time text-center">
        <Badge>{live ? liveEventTime : toHHMM(starts)}</Badge>
      </div>
      <div className="event-more" style={{ position: "relative" }}>
        <Badge>
          {" "}
          {oddCount} {`>`}
        </Badge>
      </div>
      {(!!live || isModerator) && !!event.liveStream && (
        <div className={"live-stream-badge"}>
          <Badge className="live-stream-badge">
            <PlayIcon width={12} height={12} />
          </Badge>
        </div>
      )}

      <div className="event-home">
        {showScore && (
          <span className="badge badge-secondary mr-2 py-1">
            {results && results.ft && results.ft.home}
          </span>
        )}
        {home}
      </div>
      <div className={winner3Match ? "event-away" : "event-away border-0"}>
        {showScore && (
          <span className="badge badge-secondary mr-2 py-1">
            {results && results.ft && results.ft.away}
          </span>
        )}
        {away}
      </div>

      {winner3Match && <div className="event-draw">{t("Draw")}</div>}

      {winnerHome && (
        <BetButton className="bet-ml1" key={`ml1_${_id}`} bet={winnerHome} />
      )}
      {winnerAway ? (
        <BetButton className="bet-ml2" key={`ml2_${_id}`} bet={winnerAway} />
      ) : isAnyMarket ? null : (
        <div className={cx("event-short-no-odds", { "no-draw": isNoDrawLine })} />
      )}
      {winner3Match && (
        <BetButton
          className="bet-mlx"
          key={`mlx_${_id}`}
          bet={getBet(
            event,
            {
              market: mlMarket,
              side: betSide.draw,
            },
            isVip
          )}
        />
      )}

      {hdp && (hdpHome || hdpAway) ? (
        <BetButton
          className="bet-hdp1"
          key={`hdp1_${_id}`}
          text={formatHdp(hdpValue)}
          bet={hdpHome}
        />
      ) : isAnyMarket ? null : (
        <div className={cx("event-short-no-odds", { "no-draw": isNoDrawLine })} />
      )}

      {hdp && (
        <BetButton
          className="bet-hdp2"
          key={`hdp2_${_id}`}
          text={formatHdp(-hdpValue)}
          bet={hdpAway}
        />
      )}

      {total ? (
        <BetButton
          className="bet-total1"
          key={`tt1_${_id}`}
          text={`>${totalValue}`}
          bet={getBet(
            event,
            {
              market: totalMarket,
              side: betSide.over,
              value: totalValue,
            },
            isVip
          )}
        />
      ) : isAnyMarket ? null : (
        <div
          className={cx("event-short-no-odds total-column", {
            "no-draw": isNoDrawLine,
          })}
        />
      )}
      {total && (
        <BetButton
          className="bet-total2"
          key={`tt2_${_id}`}
          text={`<${totalValue}`}
          bet={getBet(
            event,
            {
              market: totalMarket,
              side: betSide.under,
              value: totalValue,
            },
            isVip
          )}
        />
      )}
    </NavLink>
  );
};

EventShort.propTypes = {
  event: PropTypes.object,
  t: PropTypes.func,
};

export default EventShort;
