import { PAGE, PAGE_RESET } from "actions/actionTypes";
import { useHistory } from "react-router-dom";
import { useLocation, useParams } from "react-router";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { fetchAction } from "actions/fetchActions";
import { fetchPage } from "api/pageApi";
import Loading from "components/common/Loaders/Loading.tsx";
import PageContent from "pages/PageContent";
import PageMeta from "pages/PageMeta";
import { getPage, getPageParams } from "selectors/pageSelectors";
import { getEvent } from "selectors/eventSelectors";
import { getLeagues } from "selectors/sportsSelectors";
import { getExperiments } from "selectors/experimentsSelector";
import { getGame, getGameList, getGameListParams } from "../selectors/casinoSelectors";
import { homepageDescriptions, homepageTitles } from "../constants/pages";
import { defaultLanguage, geoLocationLanguages } from "../constants/i18n";
import {
  detectPageType,
  getContentVariables,
  getPageContent,
  getPageMeta,
} from "../utils/pageUtils.ts";
import { pageTypes } from "constants/urls";

const Page = ({ children }) => {
  const { i18n, t } = useTranslation();
  const pageParams = useSelector(getPageParams);
  const { page: paginationPage } = useSelector(getGameListParams);
  const experiments = useSelector(getExperiments);
  const dispatch = useDispatch();
  const params = useParams();
  const { pathname } = useLocation() || {};
  const { type, slug } = detectPageType(
    { ...pageParams, ...params, pathname },
    i18n.language
  );

  const { payload: page } = useSelector(getPage);
  const history = useHistory();
  const isGameListPage =
    ["casino", "casinoCategory"].includes(type) && paginationPage && paginationPage !== 1;

  useEffect(() => {
    return () => {
      dispatch({ type: PAGE_RESET });
    };
  }, []);

  useEffect(async () => {
    // to change geolang to 'en' always when we leave home page
    if (slug && slug !== "/" && geoLocationLanguages.includes(i18n.language)) {
      history.push(location.pathname.replace(`/${i18n.language}`, ""));
      await i18n.changeLanguage(defaultLanguage);
    }
  }, [slug]);

  useEffect(async () => {
    if (!type || type === "empty") return;
    dispatch(
      fetchAction({
        fetchFn: () => fetchPage(i18n.language, type, slug),
        actionPrefix: PAGE,
        experiments,
      })
    );
  }, [type, i18n.language, slug]);

  const leagues = useSelector(getLeagues);
  const eventId = ["eventPrematch", "eventLive"].includes(type)
    ? slug.split("-").pop()
    : 0;
  const event = useSelector((x) => getEvent(x, eventId));
  const games = useSelector(getGameList);
  const game = useSelector(getGame);

  const contentVariables = getContentVariables(
    t,
    {
      type,
      slug,
      event,
      leagues,
      games,
      game,
    },
    { league: page?.data?.league }
  );

  const isSameLanguage =
    page &&
    (i18n.language === page.data?.language ||
      (i18n.language === "en" && !page.data?.language));
  if (!page || (!page.loaded && !page.loading) || (page.loading && !page.data))
    return <Loading />;

  const { seoContent, title, metaDescription, template } = page.data || {};
  const content =
    ((i18n.language === page?.data?.language || ["privacy-policy"].includes(slug)) &&
      seoContent) ||
    (template?.content && template.content[i18n.language]);
  const contentBlocks =
    !isGameListPage && content && getPageContent(content, contentVariables);
  const pageTitle =
    getPageMeta(
      typeof title === "string" && title && isSameLanguage
        ? title
        : template?.title
        ? template.title[i18n.language]
        : "",
      contentVariables
    ) || "";
  const pageDescription =
    !isGameListPage &&
    getPageMeta(
      typeof metaDescription === "string" && metaDescription && isSameLanguage
        ? metaDescription
        : template?.metaDescription
        ? template.metaDescription[i18n.language]
        : "",
      contentVariables
    );

  if (slug === "faq") {
    if (page?.data?.content?.blocks && page.data.content.blocks.length) {
      contentBlocks?.unshift(page.data.content.blocks[0]);
    }
  }

  let hpTitle = "";
  let hpDesc = "";
  if (slug === "/" || typeof slug === "undefined") {
    hpTitle = homepageTitles[i18n.language];
    hpDesc = homepageDescriptions[i18n.language];
  } else if (slug === "live") {
    switch (i18n.language) {
      case "en":
        hpTitle = "LIVE Bitcoin Betting | Make a Bet with Crypto | Sportbet.one";
        hpDesc = "";
        break;
    }
  }

  return (
    <>
      <PageMeta
        id={page?.data?._id || "none"}
        title={pageTitle || hpTitle}
        description={pageDescription || hpDesc}
        isGameListPage={isGameListPage}
        paginationPage={paginationPage}
        isGame={type === pageTypes.casinoGame}
      />
      {children}
      {contentBlocks && <PageContent blocks={contentBlocks} slug={slug} />}
    </>
  );
};

export default Page;
