import { esportsNavItems, sports } from "constants/sports";
import { betSide, eventStatusNames } from "constants/events";
import { marketType, marketTypeClass } from "@sbetdev2/dbmodel/enums";
import { oddsFormat, oddsFormatNames } from "constants/odds";
import { hashCode } from "utils/stringUtils";
import { slugify } from "./urlUtils";

export const getPrimaryLeagueOrderThreshold = (live) => (live ? 1000 : 500);

const sportsWithML3Default = [sports.soccer, sports.cricket, sports.esports];
export const getSportMainMarket = (sportId) => {
  return sportsWithML3Default.includes(sportId)
    ? marketType.winner3Match
    : marketType.winner2Match;
};

export const getSportHandicapMarket = (sportId) => {
  if (sportId === sports.hockey) return marketType.handicapMatchAll;
  return [sports.volleyball, sports.tennis, sports.tableTennis].includes(sportId)
    ? marketType.handicapGames
    : marketType.handicapMatch;
};

export const getSportTotalMarket = (sportId) => {
  switch (sportId) {
    case sports.tableTennis:
    case sports.tennis:
    case sports.volleyball:
      return marketType.totalGames;
    case sports.hockey:
      return marketType.totalMatchAll;
    default:
      return marketType.totalMatch;
  }
};

export const marketIsDynamic = (market) => market === marketType.correctScoreMatch;

export const getOddCount = (odd) => {
  if (!odd) return 0;
  if (Array.isArray(odd)) return odd.reduce((p, x) => p + getOddCount(x), 0);
  if (!odd.bet) return 0;
  return (
    (odd.home ? 1 : 0) +
    (odd.away ? 1 : 0) +
    (odd.draw ? 1 : 0) +
    (odd.over ? 1 : 0) +
    (odd.under ? 1 : 0) +
    (odd.even ? 1 : 0) +
    (odd.odd ? 1 : 0) +
    (odd.yes ? 1 : 0) +
    (odd.no ? 1 : 0)
  );
};

export const getEventOddCount = (event) => {
  if (!event || !event.odds) return 0;
  return getOddCount(Object.values(event.odds));
};

export const getMarketClass = (market) => marketTypeClass[market];

const teamToPropMap = {
  [betSide.home]: "home",
  [betSide.away]: "away",
  [betSide.draw]: "draw",
};

const teamToProp = (team) => teamToPropMap[team];

const overUnderToPropMap = {
  [betSide.over]: "over",
  [betSide.under]: "under",
};

const overUnderToProp = (side) => overUnderToPropMap[side];

const oddEvenToPropMap = {
  [betSide.odd]: "odd",
  [betSide.even]: "even",
};

const oddEvenToProp = (side) => oddEvenToPropMap[side];

const yesNoToPropMap = {
  [betSide.no]: "no",
  [betSide.yes]: "yes",
};

const yesNoToProp = (side) => yesNoToPropMap[side];

const makeOdd = (bet, odd, name) => (odd ? { bet: !!bet, odd, name } : null);

export const findOdd = (event, { market, value, side }, isVip = true) => {
  if (!event || !market) return null;
  let modd = event.odds && event.odds[market];
  if (!isVip && modd) {
    if (Array.isArray(modd)) {
      modd = modd.filter((x) => !x.vip);
    } else if (!!modd.vip) {
      modd = null;
    }
  }
  if (!modd) return null;
  const bet = event.odds.bet;
  switch (getMarketClass(market)) {
    case "ml":
    case "ml2":
    case "dchance": {
      return makeOdd(bet && modd.bet, modd[teamToProp(side)]);
    }
    case "hdp": {
      const hdp = modd.find((x) => x._id === value);
      return hdp ? makeOdd(bet && hdp.bet, hdp[teamToProp(side)]) : null;
    }
    case "total": {
      const total = modd.find((x) => x._id === value);
      return total ? makeOdd(bet && total.bet, total[overUnderToProp(side)]) : null;
    }
    case "oddeven": {
      return makeOdd(bet && modd.bet, modd[oddEvenToProp(side)]);
    }
    case "yesno": {
      return makeOdd(bet && modd.bet, modd[yesNoToProp(side)]);
    }
    case "score": {
      const score = modd.find((x) => x._id === value);
      return score ? makeOdd(bet && score.bet, score.odd, score.name) : null;
    }
    default:
      return null;
  }
};

const makeBetFromOdd = (event, { market, value, side }, odd) => {
  if (!odd) return null;
  return {
    event: event._id,
    market,
    value,
    side,
    odd: odd.odd,
    bet: odd.bet,
    name: odd.name,
  };
};

export const getBet = (event, bet, isVip = true) => {
  const odd = findOdd(event, bet, isVip);
  return makeBetFromOdd(event, bet, odd);
};

export const formatHdp = (hdp) => (hdp >= 0 ? `+${hdp}` : `-${-hdp}`);
export const formatOverUnder = (side) =>
  side === betSide.over ? "Over" : side === betSide.under ? "Under" : "?";

export const getOddsBalancedValue = (odds, diffFunc) => {
  if (!odds || !odds.length) return undefined;
  let value = undefined;
  let best = 500000;
  for (let i = 0; i < odds.length; ++i) {
    if (!odds[i].bet) continue;
    const diff = Math.abs(diffFunc(odds[i]));
    if (diff < best) {
      value = odds[i]._id;
      best = diff;
    }
  }
  return value;
};

export const getPeriodName = (sport, market, t) => {
  if (sport && sport._id) sport = sport._id;

  switch (market) {
    case marketType.totalHomeMatchAll:
    case marketType.totalAwayMatchAll: {
      if (sport === sports.hockey) {
        return t("Match");
      }
    }
    // eslint-disable-next-line no-fallthrough
    case marketType.totalHomeMatchGames:
    case marketType.totalAwayMatchGames: {
      return t("Match");
    }
    case marketType.handicapMatchAll:
      return t("Match");
    case marketType.winner3Match:
      if (sport === sports.hockey) {
        return t("Regular Time");
      }
    // eslint-disable-next-line no-fallthrough
    case marketType.winner2Match:
    case marketType.handicapMatch:
      if (market === marketType.handicapMatch && sport === sports.tableTennis) {
        return t("Games");
      }
    // eslint-disable-next-line no-fallthrough
    case marketType.totalMatch:
    case marketType.totalHomeMatch:
    case marketType.totalAwayMatch:
    case marketType.oddEvenMatch:
    case marketType.doubleChanceMatch:
    case marketType.bothToScoreMatch:
    case marketType.cleanSheetHomeMatch:
    case marketType.cleanSheetAwayMatch:
    case marketType.correctScoreMatch: {
      if (!sport) return t("Match");
      switch (sport) {
        case sports.mma:
        case sports.boxing:
          return market === marketType.totalMatch ? t("Rounds") : "";
        case sports.soccer:
        case sports.rugbyunion:
        case sports.rugbyleague:
          return t("Full Time");
        case sports.hockey:
        // eslint-disable-next-line no-fallthrough
        case sports.esports:
        default:
          return t("Match");
      }
    }

    case marketType.totalMatchAll:
    case marketType.correctScoreMatchAll:
    case marketType.winner2MatchAll: {
      return t("Match");
    }

    case marketType.winner3Period1:
    case marketType.winner2Period1:
    case marketType.handicapPeriod1:
    case marketType.totalPeriod1: {
      switch (sport) {
        case sports.baseball:
          return t("1st Inning");
        case sports.tennis:
        case sports.volleyball:
          return t("1st Set");
        case sports.tableTennis:
          return t("1st Game");
        case sports.basketball:
        case sports.football:
        case sports.afl:
          return t("1st Quarter");
        case sports.soccer:
        case sports.rugbyleague:
        case sports.rugbyunion:
        case sports.handball:
          return t("1st Half");
        case sports.esports:
          return t("1st Map");
        default:
          return t("1st Period");
      }
    }
    case marketType.winner3Period2:
    case marketType.winner2Period2:
    case marketType.handicapPeriod2:
    case marketType.totalPeriod2: {
      switch (sport) {
        case sports.baseball:
          return t("2nd Inning");
        case sports.tennis:
        case sports.volleyball:
          return t("2nd Set");
        case sports.tableTennis:
          return t("2nd Game");
        case sports.basketball:
        case sports.football:
        case sports.afl:
          return t("2nd Quarter");
        case sports.esports:
          return t("2nd Map");
        default:
          return t("2nd Period");
      }
    }
    case marketType.winner3Period3:
    case marketType.winner2Period3:
    case marketType.handicapPeriod3:
    case marketType.totalPeriod3: {
      switch (sport) {
        case sports.baseball:
          return t("3rd Inning");
        case sports.tennis:
        case sports.volleyball:
          return t("3rd Set");
        case sports.tableTennis:
          return t("3rd Game");
        case sports.basketball:
        case sports.football:
        case sports.afl:
          return t("3rd Quarter");
        case sports.esports:
          return t("3rd Map");
        default:
          return t("3rd Period");
      }
    }
    case marketType.winner2Period4:
    case marketType.handicapPeriod4:
    case marketType.totalPeriod4: {
      switch (sport) {
        case sports.baseball:
          return t("4th Inning");

        case sports.tennis:
        case sports.volleyball:
          return t("4th Set");
        case sports.tableTennis:
          return t("4th Game");
        case sports.basketball:
        case sports.football:
        case sports.afl:
          return t("4th Quarter");
        case sports.esports:
          return t("4th Map");
        default:
          return t("4th Period");
      }
    }
    case marketType.winner2Period5:
    case marketType.handicapPeriod5:
    case marketType.totalPeriod5: {
      switch (sport) {
        case sports.baseball:
          return t("5th Inning");

        case sports.tennis:
        case sports.volleyball:
          return t("5th Set");
        case sports.tableTennis:
          return t("5th Game");
        case sports.esports:
          return t("5th Map");
        default:
          return t("5th Period");
      }
    }

    case marketType.winner2Period6: {
      switch (sport) {
        case sports.baseball:
          return t("6th Inning");
        case sports.tableTennis:
          return t("6th Game");
        case sports.esports:
          return t("6th Map");
        default:
          return t("6th Period");
      }
    }
    case marketType.winner2Period7: {
      switch (sport) {
        case sports.baseball:
          return t("7th Inning");
        case sports.tableTennis:
          return t("7th Game");
        case sports.esports:
          return t("7th Map");
        default:
          return t("7th Period");
      }
    }
    case marketType.winner2Period8: {
      switch (sport) {
        case sports.baseball:
          return t("8th Inning");
        default:
          return t("8th Period");
      }
    }
    case marketType.winner2Period9: {
      switch (sport) {
        case sports.baseball:
          return t("9th Inning");
        default:
          return t("9th Period");
      }
    }

    case marketType.tennisPoints:
      return t("Points");

    case marketType.winner2ExtraTime:
      return t("Extra Time");

    case marketType.winner2Penalties:
      return t("Penalties");

    case marketType.handicapHalf1:
    case marketType.totalHalf1:
    case marketType.winner3Half1:
    case marketType.winner2Half1:
    case marketType.doubleChanceHalf1:
      if (sport === sports.baseball) return t("1st 5 Innings");
      return t("1st Half");
    case marketType.handicapGames:
      if (sport === sports.volleyball || sport === sports.tableTennis) return t("Points");
      return t("Games");
    case marketType.totalGames:
      if (sport === sports.volleyball) return t("Points");
      if (sport === sports.tableTennis) return t("Match");
      return t("Games");
    default:
      return `Unknown (market=${market})`;
  }
};

export const getPeriods = (sport) => {
  switch (sport) {
    case sports.soccer:
    case sports.rugbyleague:
    case sports.rugbyunion:
    case sports.handball:
      return [marketType.winner2Match, marketType.winner2Half1];
    case sports.baseball:
      return [
        marketType.winner2Match,
        marketType.winner2Period1,
        marketType.winner2Period2,
        marketType.winner2Period3,
        marketType.winner2Period4,
        marketType.winner2Period5,
        marketType.winner2Half1,
        marketType.winner2Period6,
        marketType.winner2Period7,
        marketType.winner2Period8,
        marketType.winner2Period9,
      ];
    case sports.basketball:
    case sports.football:
    case sports.afl:
      return [
        marketType.winner2Match,
        marketType.winner2Period1,
        marketType.winner2Period2,
        marketType.winner2Half1,
        marketType.winner2Period3,
        marketType.winner2Period4,
      ];
    case sports.tennis:
      return [
        marketType.winner2Match,
        marketType.winner2Period1,
        marketType.winner2Period2,
        marketType.winner2Period3,
        marketType.winner2Period4,
        marketType.winner2Period5,
        marketType.handicapGames,
        marketType.tennisPoints,
      ];
    case sports.tableTennis:
      return [
        marketType.winner2Match,
        marketType.winner2Period1,
        marketType.winner2Period2,
        marketType.winner2Period3,
        marketType.winner2Period4,
        marketType.winner2Period5,
        marketType.winner2Period6,
        marketType.winner2Period7,
      ];
    case sports.hockey:
      return [
        marketType.winner2Match,
        marketType.winner2Period1,
        marketType.winner2Period2,
        marketType.winner2Period3,
        marketType.winner2ExtraTime,
        marketType.winner2MatchAll,
      ];
    case sports.volleyball:
      return [
        marketType.winner2Match,
        marketType.winner2Period1,
        marketType.winner2Period2,
        marketType.winner2Period3,
        marketType.winner2Period4,
        marketType.winner2Period5,
      ];
    case sports.esports:
      return [
        marketType.winner2Match,
        marketType.winner2Period1,
        marketType.winner2Period2,
        marketType.winner2Period3,
        marketType.winner2Period4,
        marketType.winner2Period5,
        marketType.winner2Period6,
        marketType.winner2Period7,
      ];
    default:
      return [marketType.winner2Match];
  }
};

const scoreByMarket = {
  [marketType.winner2Match]: "ft",
  [marketType.winner2Half1]: "h1",
  [marketType.winner2Period1]: "p1",
  [marketType.winner2Period2]: "p2",
  [marketType.winner2Period3]: "p3",
  [marketType.winner2Period4]: "p4",
  [marketType.winner2Period5]: "p5",
  [marketType.winner2Period6]: "p6",
  [marketType.winner2Period7]: "p7",
  [marketType.winner2Period8]: "p8",
  [marketType.winner2Period9]: "p9",
  [marketType.winner2ExtraTime]: "et",
  [marketType.winner2Penalties]: "pen",
  [marketType.winner2MatchAll]: "mt",
  [marketType.tennisPoints]: "pt",
  [marketType.handicapGames]: "gm",
};

const marketByScore = Object.keys(scoreByMarket).reduce((r, x) => {
  r[scoreByMarket[x]] = x;
  return r;
}, {});

export const getScoreByMarket = (market) => (r) => r[scoreByMarket[market]];

export const getPeriodNameFromScore = (score, sport, t) => {
  if (score === "et") {
    return "Extra Time";
  }

  const baseballPeriods = new Array(16).fill("").map((v, k) => `p${k + 10}`);
  if (baseballPeriods.includes(score)) return score;

  return getPeriodName(sport, marketByScore[score], t);
};

const combinePeriod = (periodName, betName, t) => {
  if (!periodName) return betName;
  return t("BET_PERIOD_FIRST") === "1"
    ? periodName + " " + betName
    : betName + " " + periodName;
};

const combineMarkets = (markets) => markets[0] * 256 + markets[1];

export const combinedMarketToString = (sport, event, markets, t) => {
  switch (combineMarkets(markets)) {
    case combineMarkets([marketType.totalHomeMatch, marketType.totalAwayMatch]):
    case combineMarkets([marketType.totalHomeMatchAll, marketType.totalAwayMatchAll]):
    case combineMarkets([marketType.totalHomeMatchGames, marketType.totalAwayMatchGames]):
      return combinePeriod(
        getPeriodName(sport, marketType.totalHomeMatch, t),
        t("Team Total"),
        t
      );
    case combineMarkets([marketType.cleanSheetHomeMatch, marketType.cleanSheetAwayMatch]):
      return combinePeriod(
        getPeriodName(sport, marketType.cleanSheetHomeMatch, t),
        t("Clean Sheet"),
        t
      );
    default:
      return `Unknown (markets=${markets.join()})`;
  }
};

export const marketToString = (sport, event, market, t) => {
  if (Array.isArray(market)) return combinedMarketToString(sport, event, market, t);

  const sportId = sport && sport._id;

  if (sportId === sports.soccer && market === marketType.winner2MatchAll)
    return t("To Qualify");

  switch (market) {
    case marketType.totalHomeMatch:
    case marketType.totalHomeMatchAll:
    case marketType.totalHomeMatchGames:
      return (
        combinePeriod(getPeriodName(sport, market, t), t("Total"), t) + ": " + event.home
      );
    case marketType.totalAwayMatch:
    case marketType.totalAwayMatchAll:
    case marketType.totalAwayMatchGames:
      return (
        combinePeriod(getPeriodName(sport, market, t), t("Total"), t) + ": " + event.away
      );

    case marketType.bothToScoreMatch:
      return combinePeriod(getPeriodName(sport, market, t), t("Both Teams to Score"), t);
    case marketType.cleanSheetHomeMatch:
      return (
        combinePeriod(getPeriodName(sport, market, t), t("Clean Sheet"), t) +
        ": " +
        event.home
      );
    case marketType.cleanSheetAwayMatch:
      return (
        combinePeriod(getPeriodName(sport, market, t), t("Clean Sheet"), t) +
        ": " +
        event.away
      );

    default:
      // fall to next switch
      break;
  }

  switch (getMarketClass(market)) {
    case "ml2": {
      const period = getPeriodName(sport, market, t);
      if (period === "Full Time") return t("Draw No Bet");
      return combinePeriod(period, t("Winner"), t);
    }
    case "ml":
      return combinePeriod(getPeriodName(sport, market, t), t("Result"), t);
    case "hdp":
      if (sportId === sports.baseball)
        return combinePeriod(getPeriodName(sport, market, t), t("Run Line"), t);
      return combinePeriod(getPeriodName(sport, market, t), t("Handicap"), t);
    case "total":
      return combinePeriod(getPeriodName(sport, market, t), t("Total"), t);

    case "oddeven":
      return combinePeriod(
        getPeriodName(sport, market, t),
        t("Odd") + "/" + t("Even"),
        t
      );
    case "dchance":
      return combinePeriod(getPeriodName(sport, market, t), t("Double Chance"), t);
    case "score":
      return combinePeriod(getPeriodName(sport, market, t), t("Correct Score"), t);

    default:
      return `Unknown (market=${market})`;
  }
};

export const getEventStatusName = (status) => {
  return (typeof status !== "undefined" && eventStatusNames[status]) || "Unknown";
};

export const getSportNameById = (sport) => {
  return (sport && Object.entries(sports).find((i) => i[1] === sport)[0]) || null;
};

export const decodeScore = (id) => {
  const home = Math.trunc(id / 50);
  const away = Math.trunc(id % 50);

  if (home >= 50 || away >= 50 || home < 0 || away < 0) return undefined;

  return { home, away };
};

export const betToString = (event, bet, t, wrap = false) => {
  if (!bet || !event) return "?";
  const market = bet.market;
  switch (getMarketClass(market)) {
    case "ml":
    case "ml2":
      return bet.side !== betSide.draw ? event[teamToProp(bet.side)] : t("Draw");
    case "hdp":
      return wrap
        ? {
            side: event[teamToProp(bet.side)],
            value: formatHdp(bet.side === betSide.home ? bet.value : -bet.value),
          }
        : `${event[teamToProp(bet.side)]} ${formatHdp(
            bet.side === betSide.home ? bet.value : -bet.value
          )}`;
    case "total":
      return wrap
        ? { side: t(formatOverUnder(bet.side)), value: bet.value }
        : `${t(formatOverUnder(bet.side))} ${bet.value}`;
    case "oddeven":
      return bet.side === betSide.odd
        ? t("Odd")
        : bet.side === betSide.even
        ? t("Even")
        : "Side?";
    case "dchance":
      return bet.side !== betSide.homeaway
        ? `${event[teamToProp(bet.side)]} | ${t("Draw")}`
        : `${event.home} | ${event.away}`;
    case "yesno":
      return bet.side === betSide.no
        ? t("No")
        : bet.side === betSide.yes
        ? t("Yes")
        : "Side?";
    case "score":
      return bet.name || "???";
    default:
      return `Unknown (market=${bet.market})`;
  }
};

export const toBetHash = (bet) => {
  if (!bet) return null;
  const { event, market, side, value } = bet;
  const parts = [event, market, side, value].filter((x) => !!x);
  return hashCode(parts.join(":"));
};

export const oddsFormatToString = (x) =>
  (typeof x !== "undefined" && oddsFormatNames[x]) || "Unknown";

export const formatOdd = (odd, format = oddsFormat.decimal) => {
  if (!odd || odd <= 1) return odd;
  switch (format) {
    case oddsFormat.american:
      if (odd >= 2) return "+" + Math.round((odd - 1) * 100);
      return "-" + Math.round(100 / (odd - 1));
    case oddsFormat.decimal:
    default:
      return odd.toFixed(2);
  }
};

export const slugifyBySportId = (text, sportId) => {
  switch (sportId) {
    case sports.esports:
      const gameNav = esportsNavItems.find((n) => n.title === text);
      if (!gameNav?.name) return "";
      return `${gameNav.name}-crypto-betting`;
    default:
      return slugify(text);
  }
};
