import { processFetchError } from "utils/errorUtils";
import { getActionType } from "utils/reduxUtils";
import { parseExperiments } from "../utils/experiments";
import { EXPERIMENTS_SET } from "actions/actionTypes";

const checkStateChanges = (newData, getState, selector) => {
  try {
    const prevState = getState();
    const prevData = selector(prevState);
    return JSON.stringify(newData) !== JSON.stringify(prevData);
  } catch (e) {
    console.error(e.message);
    return true;
  }
};

export const fetchAction =
  ({
    fetchFn,
    actionPrefix,
    experiments = null,
    withSilent = false,
    advancedMode = false,
    selector,
  }) =>
  async (dispatch, getState) => {
    try {
      if (!withSilent) dispatch({ type: getActionType.loading(actionPrefix) });

      const data = await fetchFn();

      if (experiments) {
        const newExperiments = parseExperiments(document.cookie);
        if (JSON.stringify(experiments) !== JSON.stringify(newExperiments)) {
          dispatch({
            type: EXPERIMENTS_SET,
            payload: newExperiments,
          });
        }
      }

      const updateNow = !advancedMode || checkStateChanges(data, getState, selector);

      if (updateNow)
        dispatch({ type: getActionType.loaded(actionPrefix), payload: { data } });
    } catch (e) {
      console.error(`Failed to fetch ${actionPrefix}`, e);
      const error = processFetchError(e);
      dispatch({ type: getActionType.error(actionPrefix), payload: { error } });
    }
  };

export const fetchClearAction = (actionPrefix) => ({
  type: getActionType.clear(actionPrefix),
});
