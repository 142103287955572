export const sports = {
  soccer: 1,
  baseball: 2,
  basketball: 3,
  mma: 4,
  boxing: 5,
  esports: 7,
  hockey: 8,
  cricket: 9,
  football: 10,
  tennis: 11,
  afl: 12,
  rugbyunion: 13,
  rugbyleague: 14,
  volleyball: 15,
  handball: 16,
  tableTennis: 17,
};

export const sportsTitle = {
  1: "Soccer",
  2: "Baseball",
  3: "Basketball",
  4: "Boxing/MMA",
  5: "Boxing/MMA",
  7: "ESports",
  8: "Hockey",
  9: "Cricket",
  10: "Football",
  11: "Tennis",
  12: "AFL",
  13: "Rugby Union",
  14: "Rugby League",
  15: "Volleyball",
  16: "Handball",
  17: "Table Tennis",
};

export const sportsNavItems = [
  {
    _id: sports.soccer,
    title: "Soccer",
    iconClass: "soccer-icon",
    name: "soccer",
  },
  {
    _id: sports.basketball,
    title: "Basketball",
    iconClass: "basketball-icon",
    name: "basketball",
  },
  {
    _id: sports.tennis,
    title: "Tennis",
    iconClass: "tennis-icon",
    name: "tennis",
  },
  {
    _id: sports.baseball,
    title: "Baseball",
    iconClass: "baseball-icon",
    name: "baseball",
  },
  {
    _id: sports.football,
    title: "Football",
    iconClass: "football-icon",
    name: "football",
  },
  {
    _id: sports.hockey,
    title: "Hockey",
    iconClass: "hockey-icon",
    name: "hockey",
  },
  {
    _id: sports.esports,
    title: "eSports",
    iconClass: "esports-icon",
    name: "esports",
  },
  {
    _id: sports.boxing,
    title: "Boxing/MMA",
    iconClass: "boxing-icon",
    name: "boxing",
  },
  {
    _id: sports.volleyball,
    title: "Volleyball",
    iconClass: "volleyball-icon",
    name: "volleyball",
  },
  {
    _id: sports.tableTennis,
    title: "Table Tennis",
    iconClass: "table-tennis-icon",
    name: "table-tennis",
  },
  {
    _id: sports.rugbyleague,
    title: "Rugby League",
    iconClass: "rugbyleague-icon",
    name: "rugby-league",
  },
  {
    _id: sports.rugbyunion,
    title: "Rugby Union",
    iconClass: "rugbyunion-icon",
    name: "rugby-union",
  },
  {
    _id: sports.cricket,
    title: "Cricket",
    iconClass: "cricket-icon",
    name: "cricket",
  },
  {
    _id: sports.afl,
    title: "AFL",
    iconClass: "afl-icon",
    name: "afl",
  },
];

export const esportsNavItems = [
  {
    title: "Dota 2",
    name: "dota-2",
  },
  {
    title: "Counter Strike",
    name: "csgo",
    imgHeight: 65,
  },
  {
    title: "League of Legends",
    name: "lol",
  },
  {
    title: "Valorant",
    name: "valorant",
  },
  {
    title: "Overwatch",
    name: "overwatch",
  },
  {
    title: "Rocket League",
    name: "rocket-league",
  },
];
