import { DOMAIN } from "actions/actionTypes";
import { compoundReducer, fetchReducer, getInitialFetchState } from "utils/reduxUtils";

const payloadKey = "payload";

const initialState = {
  [payloadKey]: getInitialFetchState(),
};

const pageReducer = () => {};

export default compoundReducer(
  initialState,
  pageReducer,
  fetchReducer(DOMAIN, payloadKey)
);
