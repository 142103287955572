import Loadable from "react-loadable";
import Loading from "components/common/Loaders/Loading.tsx";

const LazyPromoInfo = Loadable({
  loader: () => import(/* webpackChunkName: "promo-info-popup"*/ "./PromoInfo.tsx"),
  loading: Loading,
  modules: ["promo-info-popup"],
});

export default LazyPromoInfo;
