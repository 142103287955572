import Loadable from "react-loadable";
import Loading from "components/common/Loaders/Loading.tsx";

const LazyHistoryPage = Loadable({
  loader: () => import(/* webpackChunkName: "history-page"*/ "./HistoryPage.tsx"),
  loading: Loading,
  modules: ["history-page"],
});

export default LazyHistoryPage;
