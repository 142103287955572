import { PAGE, PAGE_RESET, PAGE_SET_IDS, PAGE_SET_PARAMS } from "actions/actionTypes";
import { compoundReducer, fetchReducer, getInitialFetchState } from "utils/reduxUtils";

const payloadKey = "payload";

const initialState = {
  params: {},
  skip: false,
  [payloadKey]: getInitialFetchState(),
};

const pageReducer = (state = initialState, action) => {
  switch (action.type) {
    case PAGE_SET_PARAMS:
      return { ...state, params: action.payload };
    case PAGE_SET_IDS:
      return {
        ...state,
        skip: true,
        [payloadKey]: { ...state[payloadKey], data: { relatedIds: action.payload } },
      };
    case PAGE_RESET: {
      if (state.skip) {
        return {
          ...initialState,
          [payloadKey]: {
            ...initialState[payloadKey],
            data: { relatedIds: state[payloadKey].data.relatedIds },
          },
        };
      }
      return initialState;
    }
  }
};

export default compoundReducer(initialState, pageReducer, fetchReducer(PAGE, payloadKey));
