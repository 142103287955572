import {
  CASINO_GAME_DEMO_END,
  CASINO_GAME_DEMO_ERROR,
  CASINO_GAME_DEMO_STARTED,
  CASINO_GAME_DEMO_STARTING,
  CASINO_GAME_DEPOSIT_SHOWN_SET,
  CASINO_GAME_END,
  CASINO_GAME_ERROR,
  CASINO_GAME_LOADED,
  CASINO_GAME_LOADING,
  CASINO_GAME_REFILL_ERROR,
  CASINO_GAME_REFILL_PROGRESS,
  CASINO_GAME_REFILL_SHOWN_SET,
  CASINO_GAME_REFILLED,
  CASINO_GAME_RELATED_LOADED,
  CASINO_GAME_SET_ID,
  CASINO_GAME_STARTED,
  CASINO_GAME_STARTING,
} from "../../actions/casino";

const initailState = {
  loading: false,
  error: null,
  data: null,
  related: null,
  gameLoading: false,
  gameUrl: null,
  gameError: null,
  sessionId: null,
  realPlay: false,
  menuEnabled: false,
  currency: null,
  userCurrency: null,
  rate: null,
  depositShown: false,
  refillShown: false,
  refillProgress: false,
  refillError: null,
  gameId: null,
};

export default (state = initailState, action) => {
  switch (action.type) {
    case CASINO_GAME_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
        related: null,
        depositShown: false,
        refillShown: false,
        refillError: null,
        refillProgress: false,
        url: null,
        gameLoading: false,
        gameUrl: null,
        gameError: null,
        realPlay: false,
        menuEnabled: false,
        sessionId: null,
        currency: null,
        userCurrency: null,
        rate: null,
        eosId: null,
      };
    case CASINO_GAME_LOADED: {
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload,
        depositShown: true,
        refillShown: false,
        refillError: null,
        refillProgress: false,
      };
    }
    case CASINO_GAME_ERROR:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
        depositShown: false,
        refillShown: false,
        refillError: null,
        refillProgress: false,
        url: null,
        related: null,
        gameLoading: false,
        gameError: null,
        gameUrl: null,
        realPlay: false,
        menuEnabled: false,
        sessionId: null,
        currency: null,
        userCurrency: null,
        rate: null,
        eosId: null,
      };
    case CASINO_GAME_RELATED_LOADED:
      return {
        ...state,
        related: action.payload,
      };
    case CASINO_GAME_STARTING:
    case CASINO_GAME_DEMO_STARTING:
      return {
        ...state,
        gameLoading: true,
        gameError: null,
        gameUrl: null,
        realPlay: false,
        menuEnabled: false,
        sessionId: null,
        currency: null,
        userCurrency: null,
        rate: null,
        eosId: null,
      };
    case CASINO_GAME_DEMO_STARTED:
      return {
        ...state,
        gameLoading: false,
        gameError: null,
        gameUrl: action.payload,
        realPlay: false,
        depositShown: false,
        refillShown: false,
        refillError: null,
        refillProgress: false,
        menuEnabled: true,
        sessionId: null,
        currency: null,
        userCurrency: null,
        rate: null,
        eosId: null,
      };
    case CASINO_GAME_STARTED:
      return {
        ...state,
        gameLoading: false,
        gameError: null,
        gameUrl: action.payload.gameUrl,
        sessionId: action.payload.sessionId,
        currency: action.payload.currency,
        userCurrency: action.payload.userCurrency,
        rate: action.payload.rate,
        realPlay: true,
        depositShown: false,
        refillShown: false,
        refillError: null,
        refillProgress: false,
        menuEnabled: true,
        eosId: action.payload.eosId,
      };
    case CASINO_GAME_DEMO_ERROR:
      return {
        ...state,
        gameLoading: false,
        gameError: action.payload,
        gameUrl: null,
        realPlay: false,
        depositShown: true,
        refillShown: false,
        refillError: null,
        refillProgress: false,
        sessionId: null,
        currency: null,
        userCurrency: null,
        rate: null,
        eosId: null,
      };
    case CASINO_GAME_DEPOSIT_SHOWN_SET:
      return {
        ...state,
        gameLoading: false,
        gameError: null,
        gameUrl: null,
        depositShown: action.payload,
        refillShown: false,
        refillError: null,
        refillProgress: false,
      };
    case CASINO_GAME_REFILL_SHOWN_SET:
      return {
        ...state,
        refillShown: action.payload,
        refillError: null,
        refillProgress: false,
      };
    case CASINO_GAME_REFILL_PROGRESS:
      return {
        ...state,
        refillProgress: action.payload,
      };
    case CASINO_GAME_REFILL_ERROR:
      return {
        ...state,
        depositShown: false,
        refillShown: true,
        refillError: action.payload,
      };
    case CASINO_GAME_REFILLED:
      return {
        ...state,
        refillShown: false,
        refillError: null,
      };
    case CASINO_GAME_END:
    case CASINO_GAME_DEMO_END:
      return {
        ...state,
        // data: null,
        // related: null,
        gameLoading: false,
        gameError: null,
        gameUrl: null,
        // realPlay: false,
        depositShown: true,
        refillShown: false,
        refillError: null,
        sessionId: null,
        // currency: null,
        // userCurrency: null,
        // rate: null,
        eosId: null,
      };
    case CASINO_GAME_SET_ID:
      return {
        ...state,
        gameId: action.payload,
      };
    default:
      return state;
  }
};
