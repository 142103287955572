import {
  BET_ACCEPT_CHANGES,
  BET_ADD,
  BET_AMOUNT_SET,
  BET_CANCEL,
  BET_CLEAR_ERROR,
  BET_CLEAR_PLACED,
  BET_ERROR,
  BET_LIMITS,
  BET_LOAD,
  BET_OPTION_MODIFY,
  BET_PLACED,
  BET_PLACING,
  BET_PROMO_NO_RISK,
  BET_REMOVE,
  BET_REPLACE,
  BET_WIN_AMOUNT_SET,
  CURRENCY_CHANGED,
  CURRENCY_CHANGING,
  EVENTS_LOADED,
  SPORT_LOADED,
} from "actions/actionTypes";
import produce from "immer";
import { getSimilarBet } from "utils/betUtils";
import { findOdd, toBetHash } from "utils/eventUtils";
import { getInitialCurrency } from "selectors/uiSelectors";
import { localStorageUtils, sessionStorageUtils } from "utils/browserUtils";

const initialState = {
  bets: [],
  betAmount: {},
  winAmount: "",
  error: null,
  placing: null,
  placed: null,
  betLimits: {},
  currency: getInitialCurrency(),
  options: {
    acceptWorseOdds: !!+localStorageUtils.getItem("acceptWorseOdds"),
    acceptBetterOdds: !!+localStorageUtils.getItem("acceptBetterOdds"),
  },
  transactionId: null,
  noRiskBet: null,
};

const findBetIndex = (bets, bet) =>
  bets.findIndex(
    (x) =>
      x.event === bet.event &&
      x.market === bet.market &&
      x.value === bet.value &&
      x.side === bet.side
  );

const addBetToArray = (bets, bet) => {
  const index = findBetIndex(bets, bet);
  if (index < 0) return [...bets, bet];
  const result = [...bets];
  result[index] = bet;
  return result;
};

const removeBetFromArray = (bets, bet) => {
  const index = findBetIndex(bets, bet);
  if (index < 0) return bets;
  const result = [...bets];
  result.splice(index, 1);
  return result;
};

const getUpdatedBets = (events, bets) => {
  return bets.map((bet) => {
    const [event] = events.filter((ev) => ev._id === bet.event);
    if (!event) return bet;

    let updatedBet = bet;
    const oddData = findOdd(event, bet);
    if (oddData) {
      updatedBet = {
        ...bet,
        updatedOdd: oddData.odd,
      };
    } else {
      const similarBet = getSimilarBet(event, bet);

      if (similarBet) {
        updatedBet = {
          ...bet,
          updatedOdd: similarBet.odd,
          updatedValue: similarBet.value,
        };
      }
    }
    return updatedBet;
  });
};

const saveBets = (bets, betAmount, noRiskBet) => {
  sessionStorageUtils.setItem("betslip", JSON.stringify({ bets, betAmount, noRiskBet }));
};

export default (state = initialState, action) => {
  const maxBets = state.betLimits?.maxParlayBets;
  switch (action.type) {
    case BET_LOAD: {
      const newState = produce(state, (draft) => {
        draft.bets = action.payload.bets || initialState.bets;
        draft.betAmount = action.payload.betAmount || initialState.betAmount;
        draft.noRiskBet = action.payload.noRiskBet || initialState.noRiskBet;
        draft.placed = null;
        draft.placing = null;
        draft.error = null;
      });
      saveBets(newState.bets, newState.betAmount, newState.noRiskBet);
      return newState;
    }
    case BET_ADD:
    case BET_REMOVE:
    case BET_REPLACE: {
      if (state.placing) {
        return state;
      }

      if (action.type === BET_ADD && state.bets.length === maxBets + 1) {
        return {
          ...state,
          error: `Maximum ${maxBets} bets`,
        };
      }

      const betAmount = { ...state.betAmount };

      if (action.type === BET_REPLACE) {
        const oldHash = toBetHash(action.payload.remove);
        const newHash = toBetHash(action.payload.add);
        betAmount[newHash] = betAmount[oldHash];
        delete betAmount[oldHash];
      }

      const bets =
        action.type === BET_REPLACE
          ? addBetToArray(
              removeBetFromArray(state.bets, action.payload.remove),
              action.payload.add
            )
          : action.type === BET_ADD
          ? addBetToArray(state.bets, action.payload)
          : removeBetFromArray(state.bets, action.payload);

      if (bets.length < 2 && betAmount?.parlay) {
        delete betAmount.parlay;
      }

      saveBets(bets, betAmount, state.noRiskBet);

      return {
        ...state,
        betAmount,
        bets,
        placed: null,
        error:
          action.type === BET_ADD && state.bets.length === maxBets + 1
            ? `Maximum ${maxBets} bets`
            : state.bets.length === maxBets + 1 && action.type === BET_REMOVE
            ? null
            : state.error,
      };
    }
    case BET_CLEAR_ERROR:
      return { ...state, placed: null, error: null };
    case BET_PLACING:
      return { ...state, error: null, placing: true, placed: null };
    case BET_CANCEL:
      sessionStorageUtils.removeItem("betslip");
      return {
        ...initialState,
        betLimits: state.betLimits,
        options: { ...state.options },
      };
    case BET_PLACED: {
      sessionStorageUtils.removeItem("betslip");
      return {
        ...state,
        bets: [],
        placing: null,
        error: null,
        betAmount: {},
        placed: {
          bets: action.payload.bets,
          promo: action.payload.promo,
          token: action.payload.token,
          trxId: action.payload.trxId,
          betIds: action.payload.betIds,
        },
      };
    }
    case BET_CLEAR_PLACED: {
      sessionStorageUtils.removeItem("betslip");
      return {
        ...state,
        betAmount: {},
        placing: null,
        error: null,
        placed: null,
      };
    }
    case BET_AMOUNT_SET: {
      const newState = produce(state, (draft) => {
        draft.betAmount[action.payload.hash] = action.payload.amount;
      });
      saveBets(newState.bets, newState.betAmount, newState.noRiskBet);
      return newState;
    }
    case BET_WIN_AMOUNT_SET:
      return {
        ...state,
      };
    case BET_PROMO_NO_RISK:
      return {
        ...state,
        noRiskBet: action.payload,
      };
    case CURRENCY_CHANGING: {
      const newState = produce(state, (draft) => {
        draft.betAmount = {};
      });
      saveBets(newState.bets, newState.betAmount, newState.noRiskBet);
      return newState;
    }
    case CURRENCY_CHANGED: {
      const { currencyName } = action.payload;

      return {
        ...state,
        currency: currencyName,
      };
    }
    case BET_ERROR:
      return { ...state, error: action.payload, placing: null };
    case EVENTS_LOADED:
    case SPORT_LOADED: {
      const bets = state.placing
        ? state.bets
        : getUpdatedBets(action.payload.events, state.bets);
      return {
        ...state,
        bets,
      };
    }
    case BET_ACCEPT_CHANGES: {
      const newState = produce(state, (draft) => {
        for (const bet of draft.bets) {
          const oldHash = toBetHash(bet);
          const betValue = draft.betAmount[oldHash] || "";
          delete draft.betAmount[oldHash];
          bet.odd = bet.updatedOdd || bet.odd;
          bet.value = bet.updatedValue || bet.value;
          const newHash = toBetHash(bet);
          draft.betAmount[newHash] = betValue;
          if (draft.noRiskBet === oldHash) {
            draft.noRiskBet = newHash;
          }
        }
      });
      saveBets(newState.bets, newState.betAmount, newState.noRiskBet);
      return newState;
    }
    case BET_OPTION_MODIFY: {
      const { optionName, optionValue } = action.payload;
      localStorageUtils.setItem(optionName, optionValue ? 1 : 0);

      return {
        ...state,
        options: {
          ...state.options,
          [optionName]: optionValue,
        },
      };
    }
    case BET_LIMITS:
      return {
        ...state,
        betLimits: action.payload,
      };
    default:
      return state;
  }
};
