import { loadUserStats } from "actions/userStatsActions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccountUser, getLoginToken } from "selectors/loginSelectors";
import { getWalletAddresses } from "selectors/walletSelectors";
import analytics from "utils/analyticsUtils";

export const useSetupUserStats = () => {
  const dispatch = useDispatch();

  const userAccount = useSelector(getAccountUser);
  const walletAddresses = useSelector(getWalletAddresses);
  const loginToken = useSelector(getLoginToken);

  useEffect(() => {
    dispatch(loadUserStats());
  }, [
    userAccount && userAccount.provider,
    userAccount && userAccount.eosAccount,
    walletAddresses && walletAddresses.data,
  ]);

  useEffect(() => {
    analytics.init();
  }, []);
};
