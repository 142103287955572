import React from "react";
import { useHistory } from "react-router";
import { Button } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { getLoginProgress } from "selectors/loginSelectors";
import { showSignUpPopup } from "actions/loginActions.ts";
import { useTranslation } from "react-i18next";

const SignUpButton = ({ className }) => {
  const logininProgress = useSelector(getLoginProgress);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Button
      color="success"
      size="sm"
      className={`signup-btn ${className}`}
      onClick={() => dispatch(showSignUpPopup())}
      disabled={!!logininProgress}
    >
      <span>{t("Join Now")}</span>
    </Button>
  );
};

export default SignUpButton;
