import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  getHomepageLiveView,
  getHomepageLoading,
  getHomepageSportId,
} from "selectors/homepageSelectors";
import { loadHomepageSport, resetHomepageSport } from "actions/homepageActions";
import { TableLoader } from "./ui";
import SuspensePostHydration from "components/common/Loaders/SuspensePostHydration.tsx";
import Only from "components/common/Only.tsx";

const Prematch = React.lazy(() =>
  import(/* webpackChunkName: 'prematch'*/ "./ui").then((module) => ({
    default: module.Prematch,
  }))
);
const Live = React.lazy(() =>
  import(/* webpackChunkName: 'live'*/ "./ui").then((module) => ({
    default: module.Live,
  }))
);

const HomePage: React.FC<{ live: boolean }> = ({ live }) => {
  const { type }: { type: string } = useParams();
  const activeSport = useSelector(getHomepageSportId);
  const dispatch = useDispatch();
  const liveView = useSelector(getHomepageLiveView);
  const liveViewLoading = useSelector(getHomepageLoading);
  const isSportsHubPage = type === "sports";

  useEffect(() => {
    return () => {
      dispatch(resetHomepageSport());
    };
  }, []);

  useEffect(() => {
    if (!activeSport) return;
    dispatch(loadHomepageSport());
  }, [activeSport, dispatch]);

  return (
    <Only browser>
      <div className="main-container">
        <div className="mt-2">
          <SuspensePostHydration fallback={<TableLoader />}>
            {!liveViewLoading && !isSportsHubPage && <Live live={live} />}
            {(!live || !liveView || !liveView.length) && !liveViewLoading && (
              <Prematch activeSport={activeSport} />
            )}
            {(liveViewLoading || !liveView?.length) && <TableLoader />}
          </SuspensePostHydration>
        </div>
      </div>
    </Only>
  );
};

export default HomePage;
