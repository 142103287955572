import LoginPopup from "containers/popups/LoginPopup/LoginPopup";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { getAccountUser } from "selectors/loginSelectors";

const PromoSignInPopup = () => {
  const user = useSelector(getAccountUser);
  const history = useHistory();
  const { pathname } = useLocation();
  const onClose = () => {
    history.replace(pathname);
  };

  useEffect(() => {
    if (user) onClose();
  }, [user]);

  return <LoginPopup promo={true} onClose={onClose} />;
};

export default PromoSignInPopup;
