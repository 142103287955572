import React from "react";
import { Modal, Button, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";

const ErrorPopup = ({ title = "Error", errorMessage = null, children, onClose }) => {
  const { t } = useTranslation();
  return (
    <Modal centered isOpen autoFocus toggle={onClose}>
      <ModalHeader toggle={onClose}>{t(title)}</ModalHeader>
      <ModalBody>{errorMessage ? t(errorMessage) : children}</ModalBody>
      <ModalFooter>
        <Button
          className="rounded btn-secondary btn-submit"
          style={{ minWidth: 100 }}
          onClick={onClose}
        >
          OK
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ErrorPopup;
