import { useEffect, useRef } from "react";

export const useInterval = (fn: () => void, wait: number) => {
  const timeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const callback = async function () {
      await fn();
      timeout.current = setTimeout(callback, wait);
    };
    timeout.current = setTimeout(callback, wait);

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  return timeout.current;
};

export const useAdvancedInterval = ({
  callback,
  delay,
  runOnStart,
}: {
  callback: () => void;
  delay: number;
  runOnStart?: boolean;
}) => {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current?.();
    }

    if (delay !== null) {
      if (runOnStart) savedCallback.current?.();

      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay, runOnStart]);
};
