import React, { useCallback, useState } from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

import {
  FormFeedback,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import Faq from "components/content/Faq";
import HtmlContent from "components/content/HtmlContent";
import TextExpander from "components/content/TextExpander.tsx";
import Button from "components/common/Button";
import ErrorMsg from "components/common/ErrorMsg";
import {SuccessMessage} from "components/login/SuccessMessage.tsx";

import { slugs } from "constants/pages";
import { ContentBlockType } from "constants/common.ts";
import { userFeedback } from "api/feedback";
import letterImg from "images/letter.svg";

const PageContentBlock = ({ block, slug }) => {
  if (
    block.type === ContentBlockType.TextExpander &&
    (Object.values(slugs).includes(slug))
  ) {
    return <HtmlContent {...block.data} />;
  }

  switch (block.type) {
    case ContentBlockType.TextExpander:
      return <TextExpander {...block.data} />;
    case ContentBlockType.Faq:
      return <Faq {...block.data} />;
    case ContentBlockType.Html:
      return <HtmlContent {...block.data} />;
  }

  return null;
};

PageContentBlock.propTypes = {
  block: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.object,
  }),
};

const PageContent = ({ blocks, slug }) => {
  return (
    <>
      {blocks.map((x, i) => (
        <PageContentBlock key={`page-content-block-${i}`} block={x} slug={slug} />
      ))}
      {slug && <FeedBackForm slug={slug} />}
    </>
  )
};

PageContent.propTypes = {
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      data: PropTypes.object,
    })
  ),
};

export const FeedBackForm = ({ slug }) => {
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState({});
  const [formValues, setFormValues] = useState({email: '', feedback: ''});
  const { t } = useTranslation();

  const handleFormValue = useCallback(({currentTarget}) => {
    const { name, value } = currentTarget
    setErrors(prevErrors => ({ ...prevErrors, [name]: "" }));
    setFormValues(prevState => ({ ...prevState, [name]: value }));
  }, []);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    try {
      const { data } = await userFeedback(formValues);
      
      if (data?.errors) {
        setErrors(data.errors);
        setSuccess(false);
      } else {
        setSuccess(true);
        setErrors({}); 
      }
    } catch (error) {
      setErrors({ form: "Failed to submit feedback. Please try again later." });
      setSuccess(false);
    }
  }, [formValues]);

  return (
    <div>
      {success && <Modal centered isOpen autoFocus onClosed={() => setSuccess(false)}>
        <ModalHeader>Feedback</ModalHeader>
        <ModalBody className="pt-3">
          <SuccessMessage
            icon={letterImg}
            message={t("Feedback was successfully sent")}
            onClose={() => setSuccess(false)}
          />
        </ModalBody>
      </Modal>}
      {slug === slugs.about_us &&  <div>
        <h2>Feedback</h2>
        {errors?.form && <ErrorMsg>{errors.form}</ErrorMsg>}
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <Input
              name="email"
              value={formValues.email}
              onChange={handleFormValue}
              placeholder="Email"
              invalid={!!errors?.email}
            />
            <FormFeedback>{errors.email}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Input
              className="about-us__feedback"
              name="feedback"
              type="textarea"
              value={formValues.feedback}
              onChange={handleFormValue}
              placeholder="Feedback"
              invalid={!!errors?.feedback}
            />
            <FormFeedback>{errors.feedback}</FormFeedback>
          </FormGroup>
          <Button color="success" className='signup-btn d-block btn btn-success btn-sm mt-4' type='submit'>Send feedback</Button>
        </form>
      </div>}
    </div>
  );
}


export default PageContent;

