import { Redirect, useLocation } from "react-router";
import { Switch } from "react-router-dom";
import Routes from "routes/Routes";

import BrowserOnly from "components/common/BrowserOnly.tsx";
import LazyContextButtons from "components/nav/LazyContextButtons.tsx";
import { AppContextProvider } from "contexts/AppContext";
import { useSetupExperiments } from "hooks/useSetupExperiments.ts";
import { useSetupUserStats } from "hooks/useSetupUserStats.ts";
import { useSetupHydrationState } from "hooks/useSetupHydrationState.ts";
import whitelabel from "utils/whitelabelUtils.ts";

import "@fortawesome/fontawesome-svg-core/styles.css";

const App = ({ requestUrl, requestLanguage, serverCookie, isMobile }) => {
  const { pathname } = useLocation();
  useSetupExperiments();
  useSetupUserStats();
  useSetupHydrationState({ serverCookie, isMobile });

  return (
    <AppContextProvider requestUrl={requestUrl} requestLanguage={requestLanguage}>
      <Switch>
        <Redirect
          from={`/${whitelabel.defaultLanguage()}`}
          to={pathname.replace(`/${whitelabel.defaultLanguage()}`, "")}
        />
        <Routes />
      </Switch>
      <BrowserOnly>
        <LazyContextButtons />
      </BrowserOnly>
    </AppContextProvider>
  );
};

export default App;
