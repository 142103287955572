import i18next from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

import {
  allowedLanguages,
  defaultTranslationNamespace,
  fallbackLanguage,
  translationNamespaces,
} from "constants/i18n";
import { isBrowser } from "utils/browserUtils";

const initI18n = (languageDetector) => {
  const extraOptions = {};
  if (isBrowser()) {
    i18next.use(Backend);
  }
  i18next.use(languageDetector);

  if (isBrowser()) {
    i18next.use(initReactI18next);
    extraOptions.react = {
      useSuspense: true,
    };
  }

  i18next.init({
    ns: translationNamespaces,
    defaultNS: defaultTranslationNamespace,
    fallbackLng: fallbackLanguage,
    supportedLngs: allowedLanguages,
    detection: {
      order: ["queryString", "path"],
      lookupFromPathIndex: 0,
    },
    debug: false,
    ...extraOptions,
  });

  return i18next;
};

export default initI18n;
