import { combineReducers } from "redux";
import bonusCodeReducer from "./bonusCode";
import bonusPacksReducer from "./bonusPacks";
import activeBonusReducer from "./activeBonus";
import bonusListReducer from "./bonusList";
import bonusCancelReducer from "./bonusCancel";

export default combineReducers({
  bonusCode: bonusCodeReducer,
  bonusPacks: bonusPacksReducer,
  activeBonus: activeBonusReducer,
  bonusList: bonusListReducer,
  bonusCancel: bonusCancelReducer,
});
