import { sports, sportsNavItems } from "constants/sports";
import {
  LIVE_SPORT_MONITOR_START,
  LIVE_SPORT_MONITOR_STOP,
  SPORT_DATE_FILTER_SET,
  SPORT_FILTER_SET,
  SPORT_LOAD_ERROR,
  SPORT_LOADED,
  SPORT_LOADING,
  SPORT_MONITOR_START,
  SPORT_MONITOR_STOP,
  SPORT_SET_ACTIVE,
  SPORT_SET_LIVE_SPORTS_COUNT,
  SPORT_SET_LIVE_STREAM_FILTER,
} from "actions/actionTypes";
import { combineReducers } from "redux";
import { monitorReducer } from "utils/reduxUtils";

const sportState = sportsNavItems;

sportState.forEach((x) => {
  x.loaded = false;
  x.loading = false;
  x.updating = false;
  x.error = null;
  x.last = null;

  x.liveLoaded = false;
  x.liveLoading = false;
  x.liveError = false;
  x.liveLast = null;
});

const sportsById = sportState.reduce((p, x) => {
  p[x._id] = x;
  return p;
}, {});

function byIdReducer(state = sportsById, action) {
  const { payload } = action;
  switch (action.type) {
    case SPORT_LOADING: {
      const sportId = payload.sportId;
      if (!state[sportId]) return state;

      const newSport = payload.live
        ? {
            ...state[sportId],
            liveLoading: true,
          }
        : {
            ...state[sportId],
            loading: !payload.incremental,
            updating: true,
          };
      return {
        ...state,
        [sportId]: newSport,
      };
    }
    case SPORT_LOADED: {
      const sportId = payload.sport;
      const sport = state[sportId];
      const newSport = payload.live
        ? {
            ...sport,
            liveError: null,
            liveLoading: false,
            liveLoaded: true,
            liveLast: payload.last,
          }
        : {
            ...sport,
            error: null,
            loading: payload.incremental ? sport.loading : false,
            updating: false,
            loaded: true,
            last: payload.last,
          };

      return {
        ...state,
        [sportId]: newSport,
      };
    }
    case SPORT_LOAD_ERROR: {
      const sportId = payload.sportId;
      const newSport = payload.live
        ? {
            ...state[sportId],
            liveError: payload.error,
            liveLoading: false,
          }
        : {
            ...state[sportId],
            error: payload.error,
            loading: false,
            updating: false,
          };
      return {
        ...state,
        [sportId]: newSport,
      };
    }
    default:
      return state;
  }
}

const normalizeLiveSports = (data) =>
  Array.isArray(data)
    ? data.map((x) => ({
        sportId: x.s,
        source: x.f,
        allCount: x.a,
        vipOnlyCount: x.v,
      }))
    : [];

const liveSports = (state = {}, action) => {
  const { type, payload: data = {} } = action;
  switch (type) {
    case SPORT_SET_LIVE_SPORTS_COUNT: {
      const newState = {
        ...state,
      };
      data &&
        data.length &&
        normalizeLiveSports(data).forEach((x) => {
          if (newState[x.sportId]) {
            newState[x.sportId][x.source] = {
              vipOnly: x.vipOnlyCount,
              all: x.allCount,
            };
          } else {
            newState[x.sportId] = {
              [x.source]: {
                vipOnly: x.vipOnlyCount,
                all: x.allCount,
              },
            };
          }
        });

      return newState;
    }
    default:
      return state;
  }
};

const allSportIds = sportState.map((x) => x._id);

const liveSportIds = allSportIds.filter((x) => ![sports.mma, sports.boxing].includes(x));

const preSportIds = allSportIds.filter((x) => ![sports.mma].includes(x));

function allIdsReducer(state = allSportIds) {
  return state;
}

function preIdsReducer(state = preSportIds) {
  return state;
}

function liveIdsReducer(state = liveSportIds) {
  return state;
}

function filterReducer(state = "", action) {
  return action.type === SPORT_FILTER_SET ? action.payload : state;
}

function dateFilterReducer(state = "", action) {
  return action.type === SPORT_DATE_FILTER_SET ? action.payload : state;
}

const liveStreamsFilter = (state = false, action) => {
  return action.type === SPORT_SET_LIVE_STREAM_FILTER ? action.payload : state;
};

const activeSportReducer = (state = { sportId: 0, live: false }, action) => {
  return action.type === SPORT_SET_ACTIVE ? action.payload : state;
};

export default combineReducers({
  activeSport: activeSportReducer,
  byId: byIdReducer,
  allIds: allIdsReducer,
  preIds: preIdsReducer,
  liveIds: liveIdsReducer,
  filter: filterReducer,
  dateFilter: dateFilterReducer,
  monitoring: monitorReducer(SPORT_MONITOR_START, SPORT_MONITOR_STOP),
  liveMonitoring: monitorReducer(LIVE_SPORT_MONITOR_START, LIVE_SPORT_MONITOR_STOP),
  liveSports,
  liveStreamsFilter,
});
