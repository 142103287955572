import {
  HOMEPAGE_CHANGE_SPORT,
  HOMEPAGE_RESET_SPORT,
  HOMEPAGE_SPORT_ERROR,
  HOMEPAGE_SPORT_LOAD,
  HOMEPAGE_SPORT_LOADED,
} from "actions/actionTypes";
import { getError } from "api/api";
import { fetchHomepageEvents } from "api/eventsApi";
import { getHomepageMonitoring, getHomepageSportId } from "selectors/homepageSelectors";
import { getAccountIsVip } from "selectors/loginSelectors";

export const changeHomepageSport = (sport) => ({
  type: HOMEPAGE_CHANGE_SPORT,
  payload: { sport },
});

export const resetHomepageSport = () => ({
  type: HOMEPAGE_RESET_SPORT,
});

export const loadHomepageSport = () => async (dispatch, getState) => {
  const state = getState();
  const sport = getHomepageSportId(state);
  try {
    dispatch({
      type: HOMEPAGE_SPORT_LOAD,
      payload: { sport },
    });
    const isVip = getAccountIsVip(state);
    const data = await fetchHomepageEvents(sport, isVip);
    data.prematchEvents = data.prematchEvents2;
    data.prematchLeagues = data.prematchLeagues2;
    data.liveEvents = data.liveEvents2;
    data.liveLeagues = data.liveLeagues2;
    dispatch({
      type: HOMEPAGE_SPORT_LOADED,
      payload: data,
    });

    setTimeout(() => {
      const state = getState();
      const monitoring = getHomepageMonitoring(state);
      if (!monitoring) return;
      dispatch(loadHomepageSport());
    }, 1000);
  } catch (e) {
    const error = getError(e);
    dispatch({
      type: HOMEPAGE_SPORT_ERROR,
      payload: { sport, error },
    });
  }
};
