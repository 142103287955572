import React from "react";
import { DropdownToggle as ReactDropdownToggle } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import cx from "classnames";

const DropdownToggle = (props) => {
  const { caret, caretStacked = false, children, open = false, ...restProps } = props;
  const cls = cx(`bt-dropdown-toggle`, props.className, {
    open,
    "caret-stacked": caret && caretStacked,
  });

  return (
    <ReactDropdownToggle {...restProps} caret={caret} className={cls}>
      {children}
      {caret && (
        <span className="caret-icon">
          <FontAwesomeIcon icon={faChevronDown} />
        </span>
      )}
    </ReactDropdownToggle>
  );
};

export default DropdownToggle;
