import React from "react";
import { useTranslation } from "react-i18next";
import NavLink from "components/common/NavLink.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router";
import cx from "classnames";
import ClientSideRenderer from "hoc/ClientSideRenderer.tsx";

const EventTypePicker = () => {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();

  const isHomepage =
    pathname === "/" ||
    pathname.replace(/[/]/g, "") === `${i18n.language}` ||
    pathname === "/sports";
  const isLivePage = pathname.includes("/live");
  const isCasinoPage = pathname.includes("/casino");

  return (
    <div className="d-flex justify-content-between event-type-picker-container d-lg-none">
      <NavLink
        className={cx("event-type-picker text-center", {
          active: isHomepage,
        })}
        to="/sports"
        disabled={isHomepage}
      >
        <span>{t("Sports")}</span>
      </NavLink>
      <NavLink
        className="event-type-picker d-flex justify-content-center align-items-center"
        to="/live"
        disabled={isLivePage}
      >
        <span className="mr-1">{t("Live")}</span>
        <ClientSideRenderer>
          <FontAwesomeIcon icon={faCircle} color="#f06767" className="live-icon" />
        </ClientSideRenderer>
      </NavLink>
      <NavLink
        className="event-type-picker text-center"
        to="/casino"
        style={{ position: "relative" }}
        disabled={isCasinoPage}
      >
        {t("Casino")}
      </NavLink>
    </div>
  );
};

export default EventTypePicker;
