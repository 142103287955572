import { AdvancedImage } from "@cloudinary/react";
import React, { memo, useState } from "react";
import { isMobile } from "react-device-detect";
import NavLink from "components/common/NavLink.tsx";

import { Cloudinary } from "@cloudinary/url-gen";
import { crop, fit } from "@cloudinary/url-gen/actions/resize";
import { HydrationState } from "utils/generalUtils.ts";
import analytics from "utils/analyticsUtils";

type Image = {
  url: string;
  public_id: string;
  width: number;
  height: number;
};

type Props = {
  cld: Cloudinary;
  image: Image;
  url: string;
  alt: string;
  slideMaxHeight?: number;
};

const figmaBannerWidth = 1100;

const getCropWidth = (multiplier: number, wrapperWidth?: number) =>
  Math.floor(
    ((typeof wrapperWidth !== "undefined" && wrapperWidth > 320 && wrapperWidth) ||
      (isMobile && 400) ||
      1160) * multiplier
  );

const getContainerWidth = () => {
  const state = HydrationState.getInstance().getState();
  const isSSRMobile = state ? state.isMobile : false;

  switch (true) {
    case typeof window === "undefined" && (isSSRMobile || isMobile):
      return 400;
    case typeof window === "undefined" && !isMobile:
      return 1160;
    case window.innerWidth >= 1200:
      return 1160;
    case window.innerWidth >= 992:
      return 930;
    case window.innerWidth < 992:
      return window.innerWidth - 38;
  }
};

const genCldImage = (cld: Cloudinary, image: Image) =>
  cld
    .image(image.public_id)
    .resize(fit().width("auto"))
    .resize(
      crop()
        .width(
          getCropWidth(Math.floor(image.width / figmaBannerWidth), getContainerWidth())
        )
        .height(image.height)
    )
    .quality("auto:eco")
    .format("webp");

const Slide: React.FC<Props> = memo(({ url, image, alt, cld, slideMaxHeight }) => {
  const [cldImage] = useState(genCldImage(cld, image));
  const imageAlt = alt || image?.public_id;

  const imageComponent = (
    <AdvancedImage
      loading="eager"
      fetchpriority="high"
      cldImg={cldImage}
      alt={imageAlt}
      width={image?.width}
      height={
        (image?.height > (slideMaxHeight ?? 0) && (slideMaxHeight ?? image?.height)) ||
        image?.height
      }
    />
  );

  const handleSliderClick = () => {
    analytics.slider.click({ banner: imageAlt });
  };

  return (
    <div className="slider-image-container" onClick={handleSliderClick}>
      {url && url.startsWith("http") ? (
        <a href={url} aria-label={imageAlt} target="_blank" rel="noreferrer">
          {imageComponent}
        </a>
      ) : (
        <NavLink to={url} className="p-0" aria-label={imageAlt}>
          {imageComponent}
        </NavLink>
      )}
    </div>
  );
});
Slide.displayName = "PromoSlide";

export default Slide;
