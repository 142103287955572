import { CASINO_GAMELOBBY_LOAD_ERROR, CASINO_GAMELOBBY_LOADED, CASINO_GAMELOBBY_LOADING } from "../../actions/casino";

const initailState = {
  loading: false,
  error: null,
  data: null,
  game_uuid: null,
};

export default (state = initailState, action) => {
  switch (action.type) {
    case CASINO_GAMELOBBY_LOADING:
      return {
        ...state,
        loading: true,
        game_uuid: action.payload,
        error: null,
        data: null,
      };
    case CASINO_GAMELOBBY_LOADED:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload,
      };
    case CASINO_GAMELOBBY_LOAD_ERROR:
      return {
        ...state,
        loading: false,
        data: null,
        error: action.payload,
      };
    default:
      return state;
  }
};
