import Loadable from "react-loadable";
import Loading from "components/common/Loaders/Loading.tsx";

const LazyLandingWithBonusesPage = Loadable({
  loader: () =>
    import(/* webpackChunkName: "landing-bonuses-page"*/ "./LandingWithBonusesPage.jsx"),
  loading: Loading,
  modules: ["landing-bonuses-page"],
});

export default LazyLandingWithBonusesPage;
