import { currencies } from "constants/currencies";
import {
  eosApiAccountBalance,
  eosApiBetsTransfer,
  eosApiCasinoDeposit,
  eosApiClaimDividends,
  eosApiConvertBtc,
  eosApiStakeTokens,
  eosApiUnstakeTokens,
} from "api/eosApi2";
import { walletWithdraw } from "api/walletApi";
import { buildBetMemo } from "utils/contractUtils";
import { formatAssetQty } from "utils/eosUtils";
import { getDividends as getDividendsScatter } from "./scatterapi";

const supportedProviders = {
  bitgo: "bitgo",
  ptokens: "ptokens",
};

const init = () => ({});

const getAccountBalance = async (currencyName = currencies.EOS.name) => {
  try {
    const { code, name } = currencies[currencyName];
    const { balance } = await eosApiAccountBalance(code, name);
    return balance;
  } catch (e) {
    return 0;
  }
};

const login = async () => ({});

const logout = async () => ({});

const betsTransfer = async (accountName, bets, referral, currency = "EOS") => {
  if (!accountName) throw new Error("EOS wallet not available");
  const { code, precision } = currencies[currency];
  if (referral === accountName) referral = "";

  const data = [];
  for (const item of bets) {
    const memo = buildBetMemo(item.bets, referral, item.options);
    const amount = formatAssetQty(item.amount, currency, precision);
    data.push({ memo, amount });
  }
  return await eosApiBetsTransfer(code, data);
};

const authTransaction = async () => ({});
const betComplete = async () => ({});

const withdraw = {
  [supportedProviders.ptokens]: walletWithdraw,
  [supportedProviders.bitgo]: walletWithdraw,
};

const approveWithdrawal = async () => ({});
const rejectWithdrawal = async () => ({});
const getDividends = getDividendsScatter;

const claimDividends = async () => {
  return await eosApiClaimDividends();
};

const stakeTokens = async (amount) => {
  const currency = currencies.SBET;
  const quantity = formatAssetQty(amount, currency.name, currency.precision);
  return await eosApiStakeTokens(quantity);
};

const unstakeTokens = async (amount) => {
  const currency = currencies.SBET;
  const quantity = formatAssetQty(amount, currency.name, currency.precision);
  return await eosApiUnstakeTokens(quantity);
};

const casinoDeposit = async (amount, currency, gameId) => {
  const { code, precision } = currencies[currency];
  const quantity = formatAssetQty(amount, currency, precision);
  return await eosApiCasinoDeposit(code, quantity, `g${gameId}`);
};

const convertBTC = async (amount) => {
  const currency = currencies.BTC;
  const quantity = formatAssetQty(amount, currency.name, currency.precision);
  return await eosApiConvertBtc(quantity);
};

export {
  init,
  getAccountBalance,
  login,
  logout,
  betsTransfer,
  authTransaction,
  betComplete,
  approveWithdrawal,
  rejectWithdrawal,
  getDividends,
  claimDividends,
  stakeTokens,
  unstakeTokens,
  withdraw,
  casinoDeposit,
  convertBTC,
};
