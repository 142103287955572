import { ACTION_TYPE_SLIDER } from "actions/actionTypes";
import { compoundReducer, fetchReducer, getInitialFetchState } from "utils/reduxUtils";

const payloadKey = "payload";

const initialState = {
  [payloadKey]: getInitialFetchState(),
};

export default compoundReducer(
  initialState,
  fetchReducer(ACTION_TYPE_SLIDER, payloadKey)
);
