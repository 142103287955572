export const bonusCurrencies = {
  SEOS: {
    name: "SEOS",
    realName: "EOS",
    code: "sportbetseos",
    precision: 4,
    shortDisplayPrecision: 2,
    fullDisplayPrecision: 4,
    walletDisplayPrecision: 4,
    displayName: "EOS",
    fromDisplayValue: (x) => x,
    toDisplayValue: (x) => x,
    icon: "https://sportbet.one/img/coins/EOS.png",
  },
  SBTC: {
    name: "SBTC",
    realName: "PBTC",
    code: "sportbetsbtc",
    precision: 8,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 5,
    displayName: "mBTC",
    fromDisplayValue: (x) => x && x / 1000,
    toDisplayValue: (x) => x && x * 1000,
    icon: "https://sportbet.one/img/coins/BTC.png",
  },
  SLTC: {
    name: "SLTC",
    realName: "PLTC",
    code: "sportbetsltc",
    precision: 8,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 8,
    displayName: "LTC",
    fromDisplayValue: (x) => x,
    toDisplayValue: (x) => x,
    icon: "https://sportbet.one/img/coins/LTC.png",
  },
  SETH: {
    name: "SETH",
    realName: "PETH",
    code: "sportbetseth",
    precision: 9,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 9,
    displayName: "ETH",
    fromDisplayValue: (x) => x,
    toDisplayValue: (x) => x,
    icon: "https://sportbet.one/img/coins/ETH.png",
  },
  SUSD: {
    name: "SUSD",
    realName: "USDT",
    code: "sportbetsusd",
    precision: 4,
    shortDisplayPrecision: 0,
    fullDisplayPrecision: 4,
    walletDisplayPrecision: 4,
    displayName: "USDT",
    fromDisplayValue: (x) => x,
    toDisplayValue: (x) => x,
    icon: "https://sportbet.one/img/coins/USDT.png",
  },
};

const basicCurrencies = {
  EOS: {
    name: "EOS",
    code: "eosio.token",
    precision: 4,
    shortDisplayPrecision: 2,
    fullDisplayPrecision: 4,
    walletDisplayPrecision: 4,
    displayName: "EOS",
    fromDisplayValue: (x) => x,
    toDisplayValue: (x) => x,
    icon: "https://sportbet.one/img/coins/EOS.png",
  },
  SBET: {
    name: "SBET",
    code: "sportbetsbet",
    precision: 4,
    shortDisplayPrecision: 0,
    fullDisplayPrecision: 4,
    walletDisplayPrecision: 4,
    displayName: "SBET",
    fromDisplayValue: (x) => x,
    toDisplayValue: (x) => x,
    icon: "https://sportbet.one/img/coins/SBET.png",
  },
  BTC: {
    name: "BTC",
    code: "sportbetcurr",
    precision: 8,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 5,
    displayName: "mBTC",
    fromDisplayValue: (x) => x && x / 1000,
    toDisplayValue: (x) => x && x * 1000,
    icon: "https://sportbet.one/img/coins/BTC.png",
  },
  LTC: {
    name: "LTC",
    code: "sportbetcurr",
    precision: 8,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 8,
    displayName: "LTC",
    fromDisplayValue: (x) => x,
    toDisplayValue: (x) => x,
    icon: "https://sportbet.one/img/coins/LTC.png",
    mainNetwork: "Litecoin",
    pNetwork: "EOS (pLTC)",
    walletName: "LTC",
  },
  ETH: {
    name: "ETH",
    code: "sportbetcurr",
    precision: 9,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 9,
    displayName: "ETH",
    fromDisplayValue: (x) => x,
    toDisplayValue: (x) => x,
    icon: "https://sportbet.one/img/coins/ETH.png",
    mainNetwork: "Ethereum",
    pNetwork: "EOS (pETH)",
    walletName: "ETH",
  },
  USDT: {
    name: "USDT",
    code: "tethertether",
    precision: 4,
    shortDisplayPrecision: 0,
    fullDisplayPrecision: 4,
    walletDisplayPrecision: 4,
    displayName: "USDT",
    fromDisplayValue: (x) => x,
    toDisplayValue: (x) => x,
    icon: "https://sportbet.one/img/coins/USDT.png",
    provider: "bitgo",
    walletName: "USDT",
    mainNetwork: "Ethereum (ERC20)",
    pNetwork: "EOS",
    trxNetwork: "Tron (TRC20)",
  },
  ["TRX:USDT"]: {
    name: "TRX:USDT",
    code: "tethertether",
    precision: 4,
    shortDisplayPrecision: 0,
    fullDisplayPrecision: 4,
    walletDisplayPrecision: 4,
    displayName: "TRX:USDT",
    fromDisplayValue: (x) => x,
    toDisplayValue: (x) => x,
    icon: "https://sportbet.one/img/coins/USDT.png",
    provider: "bitgo",
    walletName: "TRX USDT",
    mainNetwork: "USDT (TRC20)",
    pNetwork: "EOS network (USDT-EOS)",
  },
  PETH: {
    name: "PETH",
    code: "eth.ptokens",
    precision: 9,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 9,
    displayName: "ETH",
    fromDisplayValue: (x) => x,
    toDisplayValue: (x) => x,
    icon: "https://sportbet.one/img/coins/ETH.png",
    mainNetwork: "Ethereum",
    pNetwork: "EOS (pETH)",
    walletName: "ETH",
    provider: "ptokens",
  },
  PLTC: {
    name: "PLTC",
    code: "ltc.ptokens",
    precision: 8,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 8,
    displayName: "LTC",
    fromDisplayValue: (x) => x,
    toDisplayValue: (x) => x,
    icon: "https://sportbet.one/img/coins/LTC.png",
    mainNetwork: "Litecoin",
    pNetwork: "EOS (pLTC)",
    walletName: "LTC",
    provider: "ptokens",
  },
  PBTC: {
    name: "PBTC",
    code: "btc.ptokens",
    precision: 8,
    feePrecision: 3,
    shortDisplayPrecision: 5,
    fullDisplayPrecision: 5,
    walletDisplayPrecision: 5,
    displayName: "mBTC",
    fromDisplayValue: (x) => x && x / 1000,
    toDisplayValue: (x) => x && x * 1000,
    icon: "https://sportbet.one/img/coins/BTC.png",
    mainNetwork: "Bitcoin",
    pNetwork: "EOS (pBTC)",
    walletName: "BTC",
    provider: "ptokens",
  },
};

const bitgoCurrencies = {
  bitgoBTC: {
    ...basicCurrencies.PBTC,
    name: "BTC",
    provider: "bitgo",
  },
  bitgoLTC: {
    ...basicCurrencies.PLTC,
    name: "LTC",
    provider: "bitgo",
  },
  bitgoETH: {
    ...basicCurrencies.PETH,
    name: "ETH",
    provider: "bitgo",
  },
  bitgoTRXUSDT: {
    ...basicCurrencies.USDT,
    name: "TRX:USDT",
    code: "TRX:USDT",
    provider: "bitgo",
  },
};
export const currencies = {
  ...basicCurrencies,
  ...bonusCurrencies,
  ...bitgoCurrencies,
};

export const bonusCurrenciesOrder = [
  currencies.SBTC.name,
  currencies.SUSD.name,
  currencies.SETH.name,
  currencies.SLTC.name,
  currencies.SEOS.name,
];

export const currenciesOrderScatter = [
  currencies.EOS.name,
  currencies.PBTC.name,
  currencies.USDT.name,
  currencies.PETH.name,
  currencies.PLTC.name,
  currencies.SBET.name,
];
export const currenciesOrderSocial = [
  currencies.PBTC.name,
  currencies.USDT.name,
  currencies.PETH.name,
  currencies.PLTC.name,
  currencies.EOS.name,
  currencies.SBET.name,
];

export const fiatCurrencies = {
  USD: {
    title: "USD",
    currency: "USD",
  },
  EUR: {
    title: "EUR",
    currency: "EUR",
  },
  TRY: {
    title: "TRY",
    currency: "TRY",
  },
};

export const getBonusToken = (token) => {
  switch (token) {
    case currencies.EOS.name:
      return currencies.SEOS.name;
    case currencies.PBTC.name:
      return currencies.SBTC.name;
    case currencies.PLTC.name:
      return currencies.SLTC.name;
    case currencies.PETH.name:
      return currencies.SETH.name;
    case currencies.USDT.name:
      return currencies.SUSD.name;
    default:
      return null;
  }
};

export const normalizeToken = (token) => {
  switch (token) {
    case "PBTC":
      return "bitgoBTC";
    case "PLTC":
      return "bitgoLTC";
    case "PETH":
      return "bitgoETH";
    default:
      return token;
  }
};
